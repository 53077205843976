import React, { Component } from 'react';
import './Rules.css';
import axios from 'axios';
import Toolbar from '../../global/Toolbar/Toolbar';
import Footer from '../../global/Footer/Footer';
import SideDrawer from '../../global/SideDrawer/SideDrawer';
import Constrainter from '../../global/Constrainter/Constrainter';
import Backdrop from '../../global/Backdrop/Backdrop';
// import ro from './../../ro.json';
// import eng from './../../eng.json';

class Rules extends Component {
  state = {
    sideDrawerOpen: false,
    rules: [],
    rulesPhotos: []
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_rules.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ rules: response.data });
        // console.log(response.data);
        // console.log(this.state.rules);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_rules_photos.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ rulesPhotos: response.data });
        // console.log(response.data);
        // console.log(this.state.homePhotos);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    // let lang;
    // if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
    //   lang = ro;
    // } else {
    //   lang = eng;
    // }
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div className="Status">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter>
        <div className="content">
          <br />
          <br />

          {/* <h2>{lang === ro ? 'Regulament' : 'Rules'}</h2> */}
          <br />
          <br />
          <br />
          <div
            style={{ margin: '0 auto', width: '40%', display: 'flex', justifyContent: 'space-between' }}
            className="rules-photos"
          >
            {this.state.rulesPhotos.map(photo => {
              return (
                <img
                  src={`https://salon.fotoclubproarad.ro/api/salon/${photo.nume}`}
                  style={{ height: '8em' }}
                  alt="FotoClubPro Arad"
                />
              );
            })}
          </div>

          {this.state.rules.map(rule => {
            return (
              <div className="rule" style={{ width: '70%', margin: '0 auto', marginTop: '2em' }}>
                <h3>{localStorage.getItem('lang') === 'ro' ? rule.titlu_ro : rule.titlu_eng}</h3>
                <br />
                <p style={{ whiteSpace: 'pre-wrap', lineHeight: '1.7' }}>
                  {localStorage.getItem('lang') === 'ro' ? rule.text_ro : rule.text_eng}
                </p>
              </div>
            );
          })}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Rules;
