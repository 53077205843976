import React, { Component } from 'react';
import axios from 'axios';

class JuryPanelContent extends Component {
  state = {
    nume_ro: '',
    nume_eng: '',
    sections: []
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  logout = () => {
    localStorage.removeItem('jury_token');
    localStorage.removeItem('jury_surname');
    localStorage.removeItem('jury_name');
    localStorage.removeItem('jury_id');
    window.location.reload();
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="SectionsContent">
        <div>
          <div className="div"></div>
          <div className="AdminLoginForm">
            <p style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer' }} onClick={this.logout}>
              Ieși din cont / Log out
            </p>
            <p style={{ textAlign: 'left', cursor: 'initial' }}>
              Selectează o secțiune pentru a acorda note fotografiilor! / Select a section to start grading
              the photographs!
            </p>
            {this.state.sections.map(section => {
              return (
                <a href={`/juriu/sectiune/${section.id}/${section.nume_ro}_${section.nume_eng}`}>
                  {section.nume_ro} / {section.nume_eng}
                </a>
              );
            })}
            <br />
            <br />
            {/* <h2 style={{ textAlign: 'left' }}>Premiază fotografiile / Award the photographs</h2>
            <p style={{ textAlign: 'left', cursor: 'initial' }}>
              Selectează o secțiune pentru a premia fotografiile! / Select a section to give awards to the
              photographs!
            </p>
            {this.state.sections.map(section => {
              return (
                <a href={`/admin/sectiune/${section.id}`}>
                  {section.nume_ro} / {section.nume_eng}
                </a>
              );
            })} */}
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default JuryPanelContent;
