import React, { Component } from 'react';
import './Entries.css';

import axios from 'axios';

class EntriesContent extends Component {
  state = {
    entries: []
  };

  componentDidMount = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_admin_entries.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(response.data);
        this.setState({ entries: response.data });
        // console.log(this.state.entries);
      })
      .catch(e => {
        console.log(e);
      });
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addSection = () => {
    const data = new FormData();
    data.append('nume_ro', this.state.nume_ro);
    data.append('nume_eng', this.state.nume_eng);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_section.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  render() {
    return (
      <div className="SectionsContent">
        <div className="div"></div>

        <div className="AdminLoginForm">
          <table>
            {this.state.entries.map(entry => {
              return (
                <tr>
                  <td>
                    <a href={`/admin/concurent/${entry.id}`} className="noselect">
                      {entry.prenume} {entry.nume} (ID: {entry.id})
                      <br />
                      <br />
                    </a>
                  </td>
                  <td>
                    <span style={{ marginLeft: '20em' }}>{entry.email} </span>
                  </td>
                </tr>
              );
            })}
          </table>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default EntriesContent;
