import React, { Component } from 'react';
import './SectionContent.css';
import axios from 'axios';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

class SectionContent extends Component {
  state = {
    photos: [],
    listaPremii: [],
    modalIsOpen: false,
    modalImage: '',
    imageName: '',
    imageId: '',
    maxGrade: 1,
    selectedGrade: '',
    givenMarksPhotoIds: [],
    givenMarks: [],
    loc_premiu: '',
    titlu_premiu: '',
    acceptedPhotos: [],
    awardedPhotos: [],
    modalImageIsAccepted: undefined,
    modalImageIsAwarded: undefined,
    awardedPhoto: undefined,
    modalImageIndex: undefined,
    entries: [],
    modalImageParticipantPremiat: 0,
    info: {}
    // indexOfSelectedPhoto: ''
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = (image, graded, modalImageIndex, participantPremiat) => {
    if (graded) {
      let result = this.state.givenMarks.filter(obj => {
        return obj.id_fotografie === image.id;
      });
      this.setState({
        selectedGrade: result[0].nota * 1
      });
    } else {
      this.setState({ selectedGrade: '' });
    }

    let name = image.nume.split('foto_')[1].replaceAll('_', ' ');
    let lastIndex = name.lastIndexOf(' ');
    name = name.substring(0, lastIndex);
    lastIndex = name.lastIndexOf(' ');
    name = name.substring(0, lastIndex);

    let modalImageIsAccepted = this.state.acceptedPhotos.map(photo =>
      true ? photo.id_fotografie === image.id : false
    );

    if (modalImageIsAccepted.includes(true)) modalImageIsAccepted = true;
    else modalImageIsAccepted = false;

    const photoIndex = this.state.awardedPhotos.map(photo => {
      if (photo.id_fotografie === image.id) return true;
      return false;
    });
    let modalImageIsAwarded = false,
      awardedPhoto;
    if (photoIndex.indexOf(true) !== -1) {
      awardedPhoto = this.state.awardedPhotos[photoIndex.indexOf(true)];
      modalImageIsAwarded = true;
    }
    // console.log(awardedPhoto);
    // if (this.state.modalImageIsAccepted === false) modalImageIsAccepted = false;
    // if (this.state.modalImageIsAwarded === false) modalImageIsAwarded = false;

    this.setState({
      modalIsOpen: true,
      modalImage: image,
      imageName: name,
      imageId: image.id,
      modalImageIsAccepted,
      modalImageIsAwarded,
      awardedPhoto,
      modalImageIndex,
      modalImageParticipantPremiat: participantPremiat
    });
  };

  closeModal = () => {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modelIsOpen
    }));
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  deleteSection = () => {
    let r = window.confirm('Sigur doriți să ștergeți secțiunea? Concursul nu trebuie sa fie în desfășurare!');
    if (r === true) {
      const data = new FormData();
      data.append('id', this.props.id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_section.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          if (response.data) {
            alert('Succes!');
            window.location.href = '/admin/sectiuni';
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  componentDidMount = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('id_sectiune', this.props.id);
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_ordered_photos.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ photos: response.data });
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ maxGrade: response.data[0].nota_maxima, info: response.data[0] });
      })
      .catch(e => {
        console.log(e);
      });

    const data3 = new FormData();
    data3.append('token', localStorage.getItem('token'));
    data3.append('jury_token', localStorage.getItem('jury_token'));
    data3.append('id_sectiune', this.props.id);
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_accepted_photos.php', data3, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ acceptedPhotos: response.data });
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_awarded_photos.php', data3, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ awardedPhotos: response.data });
        // console.log(this.state.awardedPhotos);
      })
      .catch(e => {
        console.log(e);
      });

    const data4 = new FormData();
    axios
      .get('https://salon.fotoclubproarad.ro/api/salon/get_entries.php', data4, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(response.data);
        this.setState({ entries: response.data });
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_prize_list.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(response.data);
        // console.log(this.state.awardedPhotos);
        // console.log(this.state.listaPremii);
        let awardedPhotosPrizes = [];
        // console.log(this.response.data);

        setTimeout(() => { 
         awardedPhotosPrizes = this.state.awardedPhotos.map(photo => photo.id_premiu);
        // console.log(this.state.awardedPhotos);

        }, 2000);
        

        // this.setState(prevState => ({
        //   listaPremii: prevState.
        // }));
        // MAI JOS SE SCOATE PREMIUL, E IN COMMENT TEMPORAR, PANA LA THIS.SETSTATE
        setTimeout(() => { 

        let listaPremii = [];
          listaPremii = response.data.filter(prize => !awardedPhotosPrizes.includes(prize.id));

        //  listaPremii = response.data.filter(prize => !awardedPhotosPrizes.includes(prize.titlu));
        setTimeout(() => {
          this.setState({
            listaPremii
          });
        }, 2000);
        // console.log(listaPremii);
      }, 2000);



        //this.setState({listaPremii: response.data}); // partea asta se scoate cand se implementeaza comentariul de mai sus



        // console.log(awardedPhotosPrizes);
        // this.setState({
        //   listaPremii: response.data
        // });
        // console.log(this.state.listaPremii);
      })
      .catch(e => {
        console.log(e);
      });
  };

  givePrize = () => {
    if (!this.state.loc_premiu) return alert('Te rog selectează premiul! / Please select the prize!');
    const data = new FormData();

    let id_premiu = 0;

    for (const p of this.state.listaPremii) {
      if (p.titlu === this.state.loc_premiu) {
        id_premiu = p.id;
      }
    }

    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('id_fotografie', this.state.modalImage.id);
    data.append('nume_fotografie', this.state.modalImage.nume);
    data.append('id_participant', this.state.modalImage.id_participant);
    data.append('id_sectiune', this.state.modalImage.id_sectiune);
    data.append('loc', 0);
    data.append('titlu', this.state.loc_premiu);
    data.append('id_premiu', id_premiu);

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_prize.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        alert('Succes!');
        this.setState({
          awardedPhoto: {
            id_fotografie: this.state.modalImage.id,
            nume_fotografie: this.state.modalImage.nume,
            id_participant: this.state.modalImage.id_participant,
            id_sectiune: this.state.modalImage.id_sectiune,
            loc: this.state.loc_premiu,
            titlu: this.state.titlu_premiu
          },
          awardedPhotos: [
            ...this.state.awardedPhotos,
            {
              id_fotografie: this.state.modalImage.id,
              nume_fotografie: this.state.modalImage.nume,
              id_participant: this.state.modalImage.id_participant,
              id_sectiune: this.state.modalImage.id_sectiune,
              loc: this.state.loc_premiu,
              titlu: this.state.titlu_premiu
            }
          ],
          modalImageIsAwarded: true
        });

        window.location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  acceptPhoto = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('id_fotografie', this.state.modalImage.id);
    data.append('nume_fotografie', this.state.modalImage.nume);
    data.append('id_participant', this.state.modalImage.id_participant);
    data.append('id_sectiune', this.state.modalImage.id_sectiune);
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_accepted_photo.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        alert('Succes!');
        this.setState({
          modalImageIsAccepted: true,
          acceptedPhotos: [
            ...this.state.acceptedPhotos,
            {
              id_fotografie: this.state.modalImage.id,
              nume_fotografie: this.state.modalImage.nume,
              id_participant: this.state.modalImage.id_participant,
              id_sectiune: this.state.modalImage.id_sectiune
            }
          ]
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  deletePrize = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('id_fotografie', this.state.modalImage.id);

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/delete_prize.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        alert('Premiu șters cu succes!');
        this.setState({ modalImageIsAwarded: false });
        window.location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  deleteAcceptedPhoto = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('id_fotografie', this.state.modalImage.id);

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/delete_accepted_photo.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        alert('Acceptarea fotografiei ștearsă cu succes!');
        this.setState({ modalImageIsAccepted: false });
        // console.log(this.state.)
        this.setState(prevState => ({
          acceptedPhotos: prevState.acceptedPhotos.filter(
            photo => photo.id_fotografie !== prevState.modalImage.id
          )
        }));
      })
      .catch(e => {
        console.log(e);
      });
  };

  next = () => {
    const nextImage = this.state.photos[this.state.photos.indexOf(this.state.modalImage) + 1];
    let participantPremiat = 0;
    for (const awardedPhoto of this.state.awardedPhotos) {
      if (awardedPhoto.id_participant === nextImage.id_participant) participantPremiat = 1;
    }

    this.openModal(
      nextImage,
      this.state.givenMarksPhotoIds.includes(nextImage.id) ? true : false,
      this.state.modalImageIndex + 1,
      participantPremiat
    );
  };

  prev = () => {
    const prevImage = this.state.photos[this.state.photos.indexOf(this.state.modalImage) - 1];
    let participantPremiat = 0;
    for (const awardedPhoto of this.state.awardedPhotos) {
      if (awardedPhoto.id_participant === prevImage.id_participant) participantPremiat = 1;
    }
    this.openModal(
      prevImage,
      this.state.givenMarksPhotoIds.includes(prevImage.id) ? true : false,
      this.state.modalImageIndex + 1,
      participantPremiat
    );
  };

  render() {
    return (
      <div className="SectionsContent SectionContent JurySectionContent">
        {this.state.info.rezultate_afisate === '0' || localStorage.getItem('token') ? (
          <div>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={() => this.setState({ modalIsOpen: false })}
              style={{
                // Pentru modal
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)'
                }
              }}
              contentLabel="Modal"
            >
              {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
              <div
                className="modal-image-wrapper"
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <img
                  src={`https://salon.fotoclubproarad.ro/api/salon/${this.state.modalImage.nume}`}
                  className="jury-img"
                  alt=""
                  style={{ margin: '0 auto' }}
                />
                <div className="title" style={{ alignSelf: 'center', marginTop: '0.7em' }}>
                  <p style={{ textAlign: 'center' }}>
                    <b>{this.state.modalImageIndex + 1}. </b>
                    <b>{this.state.imageName}</b>
                    <br />
                    <b>ID fotograf: {this.state.modalImage.id_participant}</b>
                  </p>
                  <p style={{ fontSize: '18px', textAlign: 'center', color: 'darkred', fontWeight: 'bold' }}>
                    {this.state.modalImageParticipantPremiat
                      ? 'Fotograful a fost deja premiat! / This photographer has already been awarded!'
                      : ''}
                  </p>
                  {/* <p style={{ textAlign: 'center' }}>
                <b>
                  {this.state.entries.map(entry => {
                    if (entry.id === this.state.modalImage.id_participant)
                      return `${entry.prenume} ${entry.nume} (${entry.tara}) ID: ${entry.id}`;
                    else return null;
                  })}
                </b>
              </p> */}
                  <p style={{ fontSize: '18px', textAlign: 'center', color: '#00ff00' }}>
                    <b>
                      {this.state.modalImageIsAccepted ? 'Fotografie acceptată / Accepted photograph' : ''}
                    </b>
                  </p>
                  <p style={{ fontSize: '18px', textAlign: 'center', color: '#00ff00' }}>
                    <b>
                      {this.state.awardedPhoto && this.state.modalImageIsAwarded
                        ? 'Fotografie premiată / Awarded photograph: ' + this.state.awardedPhoto.titlu
                        : ''}
                    </b>
                  </p>
                </div>

                <div style={{ alignSelf: 'center' }}>
                  <form>
                    <br />
                    {/* <input
                  type="text"
                  onChange={this.inputChange}
                  name="titlu_premiu"
                  placeholder="Titlul premiului"
                  style={{
                    padding: '0.2em',
                    marginRight: '0.4em',
                    width: '300px'
                  }}
                /> */}
                    <select
                      name="loc_premiu"
                      onChange={this.inputChange}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '400px',
                        cursor: 'pointer'
                      }}
                    >
                      <option value="" selected>
                        Selectează premiul / Select prize
                      </option>
                      {this.state.listaPremii.map(premiu => (
                        <option value={premiu.titlu}>{premiu.titlu}</option>
                      ))}
                    </select>
                    <button
                      type="button"
                      onClick={this.givePrize}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                        width: '150px'
                      }}
                    >
                      Trimite / Submit
                    </button>
                    {/* <button
                  type="button"
                  onClick={this.acceptPhoto}
                  style={{
                    padding: '0.2em',
                    margin: 0,
                    cursor: 'pointer',
                    width: '150px'
                  }}
                >
                  Acceptă fotografia
                </button> */}
                    <br />
                    <div style={{ marginTop: '0.4em', textAlign: 'center' }}>
                      <button
                        type="button"
                        onClick={this.deletePrize}
                        style={{
                          padding: '0.2em',
                          marginRight: '0.5em',
                          cursor: 'pointer',
                          width: '150px'
                        }}
                      >
                        Șterge premiul / Delete prize
                      </button>
                      {/* <button
                    type="button"
                    onClick={this.deleteAcceptedPhoto}
                    style={{
                      padding: '0.2em',
                      margin: 0,
                      cursor: 'pointer',
                      width: '150px'
                    }}
                  >
                    Șterge acceptarea
                  </button> */}
                    </div>
                  </form>
                </div>

                <div className="next-prev">
                  <span onClick={this.prev}>
                    <FontAwesomeIcon icon={faArrowLeft} className="icon" size="2x" />
                  </span>
                  <br />
                  <span onClick={this.next}>
                    <FontAwesomeIcon icon={faArrowRight} className="icon" size="2x" />
                  </span>
                </div>
              </div>
            </Modal>

            <div className="AdminLoginForm">
              {localStorage.getItem('token') ? (
                <div>
                  <p style={{ cursor: 'initial' }}>
                    <h3
                      className="delete-button"
                      onClick={this.deleteSection}
                      style={{ display: 'inline', textAlign: 'left', cursor: 'pointer' }}
                    >
                      Ștergeți secțiunea
                    </h3>
                  </p>
                  <p>
                    <span style={{ color: 'red', fontWeight: 'bold', cursor: 'initial' }}>
                      Atenție! Nu șterge o secțiune după începerea concursului!
                    </span>
                  </p>
                </div>
              ) : (
                ''
              )}

              <a href={`/admin/premiate/${this.props.id}`}>
                Vezi fotografiile premiate / See awarded photographs
              </a>
              <a href={`/admin/acceptate/${this.props.id}`}>
                Vezi fotografiile acceptate / See accepted photographs
              </a>
              <a href={`/admin/accepta/${this.props.id}`}>Acceptă fotografii / Accept photographs</a>
            </div>

            {/* */}

            <div className="AdminLoginForm">
              <h3 style={{ marginLeft: '2.3em', marginBottom: '2.5em', lineHeight: '1.7' }}>
                După înscrierea concurenților aici vor apărea fotografiile secțiunii / After contestants
                register their photographs, they will show up here.
                <br />
                Dacă fotografiile au primit note din partea juriului, acestea vor fi ordonate dupa media
                notelor / If the photographs were graded, they will be ordered by the highest grades received.
                <br />
                <b style={{ color: 'red' }}>
                  Fotografiile cu margine roșie au ca autor un fotograf deja premiat / The photographs with
                  red borders have an author that has already been awarded!
                </b>
                <br />
                <b style={{ color: 'green' }}>
                  Fotografiile cu margine verde au fost premiate deja / The photographs with green borders
                  have already been awarded!
                </b>
                <br />
                Apăsați pe o fotografie pentru a acorda un premiu / Click on a photograph to give a prize.
                <br />
                Vă rugăm să acordați premiile în ordinea importanței lor (veți acorda întâi premiul cel mai
                important iar la final cel mai puțin important) / Please give the awards in this order: you
                will start with the most important award and finish with the least important one.
              </h3>
              <div class="gallery">
                {this.state.photos.map((photo, index) => {
                  // console.log(photo);
                  let participantPremiat = 0,
                    fotografiePremiata = 0;
                  for (const awardedPhoto of this.state.awardedPhotos) {
                    if (awardedPhoto.id_participant === photo.id_participant) participantPremiat = 1;
                    if (awardedPhoto.id_fotografie === photo.id) fotografiePremiata = 1;
                  }

                  let border = '8px solid transparent';
                  if (participantPremiat) border = '8px solid red';
                  if (fotografiePremiata) border = '8px solid green';

                  if (index <= 149)
                    return (
                      <figure class="gallery__item gallery__item--1">
                        <img
                          loading="lazy"
                          src={`https://salon.fotoclubproarad.ro/api/salon/${photo.nume}`}
                          class="gallery__img"
                          alt="FotoClubPro Arad"
                          style={{
                            border: border,
                            borderRadius: '0px'
                          }}
                          onClick={() =>
                            this.openModal(
                              photo,
                              this.state.givenMarksPhotoIds.includes(photo.id) ? true : false,
                              index,
                              participantPremiat
                            )
                          }
                        />
                        <div style={{ fontSize: '20px' }}>
                          <b>
                            {index + 1}. ({(Math.round(photo.medie * 100) / 100).toFixed(2)}P) ID fotograf: {photo.id_participant}
                          </b>
                        </div>
                      </figure>
                    );

                    return undefined; // Added later, try removing if buggy
                })}
              </div>
            </div>
          </div>
        ) : (
          <h2>
            Rezultatele au fost afișate! Nu se mai pot premia fotografii! / The results are public! You can no
            longer award photographs.
          </h2>
        )}
      </div>
    );
  }
}

export default SectionContent;
