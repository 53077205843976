import React, { Component } from 'react';
import './SectionsContent.css';
import axios from 'axios';

class SectionsContent extends Component {
  state = {
    nume_ro: '',
    nume_eng: '',
    sections: []
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addSection = () => {
    const data = new FormData();
    data.append('nume_ro', this.state.nume_ro);
    data.append('nume_eng', this.state.nume_eng);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_section.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  render() {
    return (
      <div className="SectionsContent">
        <div className="div"></div>

        <div className="AdminLoginForm">
          {this.state.sections.map(section => {
            return (
              <a href={`/admin/sectiune/${section.id}`}>
                {section.nume_ro} ({section.nume_eng})
              </a>
            );
          })}

          <h3>Adaugă o secțiune nouă</h3>
          <p>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              Atenție! Nu adăuga o secțiune după începerea concursului!
            </span>
          </p>

          <form>
            <br />
            <input
              type="text"
              onChange={this.inputChange}
              name="nume_ro"
              placeholder="Numele secțiunii în limba romnână"
            />
            <br />
            <br />

            <input
              type="text"
              onChange={this.inputChange}
              name="nume_eng"
              placeholder="Numele secțiunii în limba engleză"
            />
            <br />
            <br />
            <button type="button" onClick={this.addSection}>
              Confirmă
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default SectionsContent;
