import React, { Component } from 'react';
import axios from 'axios';
import './Table.css';
import ro from './../../../ro.json';
import eng from './../../../eng.json';

class Table extends Component {
  state = {
    entries: [],
    sections: []
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/salon/get_entries.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ entries: response.data });
        // console.log(this.state);

        let entries = response.data;
        for (let entry of entries) {
          entry.sections = [];
          for (const photo of entry.fotografii) {
            if (!entry.sections[photo.id_sectiune]) entry.sections[photo.id_sectiune] = 1;
            else entry.sections[photo.id_sectiune]++;
          }
        }

        this.setState({ entries: entries });
        console.log(this.state);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        console.log(this.state);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    return (
      <div className="Table" style={{ overflow: 'auto' }}>
        <table>
          <tr className="first">
            <th>ID</th>
            <th>{lang.status.table.name}</th>
            {/* <th>{lang.status.table.last_name}</th>
            <th>{lang.status.table.photographic_titles}</th> */}
            <th>{lang.status.table.country}</th>
            {this.state.sections.map(section => {
              return <th>{lang === ro ? section.nume_ro : section.nume_eng}</th>;
            })}
            <th>{lang.status.table.payment}</th>
          </tr>
          {this.state.entries.map(entry => {
            const sections = this.state.sections;
            return (
              <tr>
                <th>{entry.id}</th>
                <th>
                  {entry.prenume} {entry.nume} {entry.titluri_fotografice}
                </th>
                <th>{entry.tara}</th>
                {sections.map(section => {
                  return <th>{entry.sections[section.id] ? entry.sections[section.id] : '-'}</th>;
                })}
                <th>{entry.plata === '0' ? lang.status.table.no : lang.status.table.yes}</th>
              </tr>
            );
          })}
        </table>
        <div className="light"></div>

        <div className="dark"></div>
      </div>
    );
  }
}

export default Table;
