import React, { Component } from 'react';
import axios from 'axios';
import './Root.css';
import Toolbar from './../../global/Toolbar/Toolbar';
import Footer from './../../global/Footer/Footer';
import SideDrawer from './../../global/SideDrawer/SideDrawer';
import Constrainter from './../../global/Constrainter/Constrainter';
import Backdrop from './../../global/Backdrop/Backdrop';
// import Countdown from 'react-countdown';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation, Pagination, FreeMode, Thumbs, Autoplay } from 'swiper';
// import banner from './banner.jpeg';

SwiperCore.use([Navigation, Pagination, FreeMode, Thumbs, Autoplay]);

class Root extends Component {
  state = {
    sideDrawerOpen: false,
    info: {},
    sections: [],
    prizeList: [],
    homePhotos: [],
    special_prizes: []
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_special_prizes.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ special_prizes: response.data });
        // console.log(response.data);
        // console.log(this.state.rules);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ info: response.data[0] });
        // console.log(this.state.info);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_home_photos.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ homePhotos: response.data });
        // console.log(response.data);
        // console.log(this.state.homePhotos);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        // console.log(this.state.sections);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_prize_list.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ prizeList: response.data });
        // console.log(this.state.prizeList);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    let backdrop;
    const deschis = localStorage.getItem('lang') === 'ro' ? 'Deschis' : 'Open';
    const inchis = localStorage.getItem('lang') === 'ro' ? 'Închis' : 'Closed';
    const deschidere = localStorage.getItem('lang') === 'ro' ? 'Jurizare' : 'Judging';
    const incheiere = localStorage.getItem('lang') === 'ro' ? 'Încheiere' : 'Closing';
    const rezultate = localStorage.getItem('lang') === 'ro' ? 'Rezultate' : 'Results';
    const sectiuni = localStorage.getItem('lang') === 'ro' ? 'Secțiuni' : 'Sections';
    const recunoscut = localStorage.getItem('lang') === 'ro' ? 'Recunoscut' : 'Recognized';
    const peDeplin = localStorage.getItem('lang') === 'ro' ? 'Complet' : 'Fully';
    const premii = localStorage.getItem('lang') === 'ro' ? 'Premii' : 'Awards';
    const medalii = localStorage.getItem('lang') === 'ro' ? 'Medalii' : 'Medals';
    const mentiuni = localStorage.getItem('lang') === 'ro' ? 'Mențiuni' : 'Mentions';

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div className="Root">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter>
        <div className="content">
          <br />
          <br />

          {/* <img
            src={`https://salon.fotoclubproarad.ro/api/salon/slideshow/banner.jpeg`}
            alt=""
            className="banner"
          /> */}
          <div className="slider">
            <Swiper
              navigation={true}
              modules={[Pagination]}
              className="mySwiper"
              slidesPerGroup={1}
              watchSlidesProgress={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
            >
              {this.state.homePhotos.map(photo => {
                return (
                  <SwiperSlide>
                    <img src={`https://salon.fotoclubproarad.ro/api/salon/${photo.nume}`} alt="" />
                    <br />
                    <p className="details">{photo.detalii}</p>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          {/* <div className="countdown">
            <span>
              <Countdown
                date={
                  new Date(
                    this.state.info.inscrieri_deschise === '1'
                      ? this.state.info.data_incheiere
                      : this.state.info.data_incepere
                  )
                }
              />
            </span>
          </div> */}
          <br />
          <br />
          <div className="cards">
            <div className="card">
              <div>
                <h3>Calendar</h3>
                <p className="circle">{this.state.info.inscrieri_deschise === '1' ? deschis : inchis}</p>
              </div>
              <div>
                <p>
                  {incheiere}: {this.state.info.data_incheiere}
                </p>
                <p>
                  {deschidere}: {this.state.info.data_incepere}
                </p>
                <p>
                  {rezultate}: {this.state.info.data_afisare_rezultate}
                </p>
              </div>
            </div>
            <div className="card">
              <div>
                <h3>{sectiuni}</h3>
                <p className="circle">{this.state.sections.length}</p>
              </div>
              <div>
                {this.state.sections.map(section => (
                  <p>{localStorage.getItem('lang') === 'ro' ? section.nume_ro : section.nume_eng}</p>
                ))}
              </div>
            </div>
            <div className="card">
              <div>
                <h3>{recunoscut}</h3>
                <p className="circle">{peDeplin}</p>
              </div>
              <div>
                <p style={{ whiteSpace: 'pre-wrap' }}>{this.state.info.coduri_patronaje}</p>
              </div>
            </div>
            <div className="card">
              <div>
                <h3>{premii}</h3>
                <p className="circle">
                  {this.state.prizeList.length * this.state.sections.length +
                    this.state.special_prizes.length}
                </p>
              </div>
              <div>
                <p>
                  {medalii}
                  <br />
                  {mentiuni}
                </p>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Root;
