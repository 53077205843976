import React, { Component } from 'react';
import axios from 'axios';
import './Form.css';
import ro from './../../../ro.json';
import eng from './../../../eng.json';
import countries from './../../../countries.json';
import loadGif from './load.gif';

class Form extends Component {
  state = {
    nume: '',
    prenume: '',
    email: '',
    telefon: '',
    titluri: '',
    tara: '',
    orasul: '',
    adresa: '',
    codPostal: '',
    emailError: '',
    club: '',
    sectiunea1: [],
    sectiunea2: [],
    sectiunea3: [],
    sectiunea4: [],
    sectiunea5: [],
    // Mai jos vin numele fotografiilor pentru cele maxim 5 sectiuni
    nume1: [],
    nume2: [],
    nume3: [],
    nume4: [],
    nume5: [],
    //
    idSectiunea1: undefined,
    idSectiunea2: undefined,
    idSectiunea3: undefined,
    idSectiunea4: undefined,
    idSectiunea5: undefined,
    check: false,
    errorMessage: '',
    sizeError: false,
    sections: [],
    loading: false,
    showSections: false,
    uploadOk: false,
    clicked: false
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        response.data.forEach((section, index) => {
          this.setState({
            [`idSectiunea${index + 1}`]: section.id
          });
        });
        // console.log(this.state);
      })
      .catch(e => {
        console.log(e);
      });
  };

  fileChanged = event => {
    // exemplu name: 2-1 (sectiunea 2 poza 1)
    const section = `sectiunea${event.target.name.split('-')[0]}`;
    const imageNumber = event.target.name.split('-')[1] - 1;
    // console.log(section);
    // console.log(event.target.files[0]);
    const tempSectionArray = [...this.state[section]];
    tempSectionArray[imageNumber] = event.target.files[0];
    this.setState({
      [section]: tempSectionArray
    });
    // console.log(this.state);
  };

  photoNameChanged = event => {
    // exemplu name: 2-1 (sectiunea 2 poza 1)
    const name = `nume${event.target.name.split('-')[0]}`;
    const imageNumber = event.target.name.split('-')[1] - 1;
    // console.log(section);
    // console.log(event.target.files[0]);
    const tempSectionArray = [...this.state[name]];
    tempSectionArray[imageNumber] = event.target.value;
    this.setState({
      [name]: tempSectionArray
    });
    // console.log(this.state);
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkboxChanged = event => {
    this.setState({ check: event.target.checked });
  };

  submitHandler = event => {
    // console.log('INCEPUT');
    this.setState({ sizeError: false });
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    event.preventDefault();
    const reqBody = { ...this.state };
    // console.log(reqBody);

    if (
      this.state.sectiunea1.length === 0 &&
      this.state.sectiunea2.length === 0 &&
      this.state.sectiunea3.length === 0 &&
      this.state.sectiunea4.length === 0 &&
      this.state.sectiunea5.length === 0
    ) {
      this.errorEmail(ro.registration.form.errors[1]);
      return this.setState({ errorMessage: lang.registration.form.errors[1] });
    }

    for (let i = 1; i <= 5; i++) {
      for (let j = 0; j <= 5; j++)
        if (reqBody[`sectiunea${i}`][j] !== undefined && !reqBody[`nume${i}`][j]) {
          this.errorEmail(ro.registration.form.errors[2]);
          return this.setState({ errorMessage: lang.registration.form.errors[2] });
        }
    }

    this.setState({ errorMessage: '' });
    let fotografii = [];

    if (this.state.sectiunea1 !== []) {
      this.state.sectiunea1.forEach((fotografie, index) => {
        fotografii.push({
          nume: this.state.nume1[index].replace(/\s/g, '_'),
          id_sectiune: this.state.idSectiunea1,
          fotografie
        });
      });
    }

    if (this.state.sectiunea2 !== []) {
      this.state.sectiunea2.forEach((fotografie, index) => {
        fotografii.push({
          nume: this.state.nume2[index].replace(/\s/g, '_'),
          id_sectiune: this.state.idSectiunea2,
          fotografie
        });
      });
    }

    if (this.state.sectiunea3 !== []) {
      this.state.sectiunea3.forEach((fotografie, index) => {
        fotografii.push({
          nume: this.state.nume3[index].replace(/\s/g, '_'),
          id_sectiune: this.state.idSectiunea3,
          fotografie
        });
      });
    }

    if (this.state.sectiunea4 !== []) {
      this.state.sectiunea4.forEach((fotografie, index) => {
        fotografii.push({
          nume: this.state.nume4[index].replace(/\s/g, '_'),
          id_sectiune: this.state.idSectiunea4,
          fotografie
        });
      });
    }

    if (this.state.sectiunea5 !== []) {
      this.state.sectiunea5.forEach((fotografie, index) => {
        fotografii.push({
          nume: this.state.nume5[index].replace(/\s/g, '_'),
          id_sectiune: this.state.idSectiunea5,
          fotografie
        });
      });
    }

    // return console.log(fotografii);

    for (const fotografie of fotografii)
      if (fotografie.fotografie.size / 1024 / 1024 > 6) {
        // limita de 5MB per poza
        this.errorEmail(ro.registration.form.errors[4]);

        return this.setState({ errorMessage: lang.registration.form.errors[4] });
      }

    for (const fotografie of fotografii)
      if (fotografie.fotografie.size / 1024 / 1024 < 2.0) {
        // limita de minim 3MB per poza
        this.errorEmail(ro.registration.form.errors[5]);
        return this.setState({ errorMessage: lang.registration.form.errors[5] });
      }

    // for (const fotografie of fotografii) {

    fotografii.forEach(fotografie => {
      var reader = new FileReader();

      //Read the contents of Image File.
      reader.readAsDataURL(fotografie.fotografie);
      const that = this;
      reader.onload = e => {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (width >= height && width < 3000) {
            that.setState({ sizeError: true });
            // return false;
          }

          if (width < height && height < 3000) {
            that.setState({ sizeError: true });
            // console.log(that.state.sizeError);

            // return false;
          }

          if (that.state.sizeError) {
            that.errorEmail(ro.registration.form.errors[6]);
            that.setState({ errorMessage: lang.registration.form.errors[6], uploadOk: false });
            return false;
          }
          // console.log('AM AJUNS');
          that.setState({ uploadOk: true });
          return true;
        };
      };
    });
    this.setState({ clicked: true });
    if (!this.state.clicked) {
      setTimeout(() => {
        if (this.state.uploadOk) {
          const data = new FormData();
          data.append('prenume', this.state.prenume);
          data.append('nume', this.state.nume);
          data.append('email', this.state.email);
          data.append('telefon', this.state.telefon);
          data.append('titluri', this.state.titluri);
          data.append('club', this.state.club);
          data.append('tara', this.state.tara);
          data.append('oras', this.state.orasul);
          data.append('adresa', this.state.adresa);
          data.append('cod_postal', this.state.codPostal);
          // data.append('fotografii', this.state.fotografii);\
          fotografii.forEach(fotografie => {
            // De ex: Beautiful Landscape_3 (fotografie numita Beautiful Landscape in sectiunea 3)
            // In PHP va veni Beautiful_Landscape_3
            fotografie.nume.replace(/\s/g, '_');
            data.append(`foto_${fotografie.nume}_${fotografie.id_sectiune}`, fotografie.fotografie);
          });
          // for (var value of data.keys()) {
          //   // console.log(value);
          // }

          this.setState({ loading: true });
          axios
            .post('https://salon.fotoclubproarad.ro/api/salon/upload_entry.php', data, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Client-ID aa4f70aa066eefe'
              }
              // Authorization: 'Bearer ' + varToken
            })
            .then(response => {
              // console.log(response.data);
              if (response.data) {
                alert('Succes!');
                window.location.href = '/plată';
                // window.location.href = '/status';
              } else alert('Eroare!');
            })
            .catch(e => {
              console.log(e.response);
              console.log('Error');
            });
        }
      }, 2000);
    }
  };

  showSections = event => {
    event.preventDefault();
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    if (
      !this.state.nume ||
      !this.state.prenume ||
      !this.state.email ||
      !this.state.telefon ||
      !this.state.tara ||
      !this.state.orasul ||
      !this.state.adresa ||
      !this.state.codPostal
    ) {
      this.errorEmail(ro.registration.form.errors[0]);
      return this.setState({ errorMessage: lang.registration.form.errors[0] });
    }

    if (this.state.titluri.length !== 0 && (this.state.titluri.split(",").length - 1) > 2 ) {
      this.errorEmail(ro.registration.form.errors[7]);
      return this.setState({ errorMessage: lang.registration.form.errors[7] });
    }

    if (!this.state.check) {
      this.errorEmail(ro.registration.form.errors[3]);
      return this.setState({ errorMessage: lang.registration.form.errors[3] });
    }

    

    return this.setState({ showSections: true, errorMessage: '' });
  };

  errorEmail = err => {
    const reqBody = { ...this.state };
    reqBody.emailError = err;
    axios
      .post('https://salon.fotoclubproarad.ro/api/salon/registration_error_email.php', reqBody)
      .then(response => {
        // this.response = { ...response };
      })
      .catch(e => {
        // alert('Error sending the message');
      });
  };

  render() {
    let lang, loadingContent, content;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
      loadingContent = (
        <h2 style={{ textAlign: 'center' }}>
          Se încarcă... <br />
          <b style={{ color: 'red' }}>
            Veți fi redirecționat imediat. Vă rugăm să nu părăsiți această pagină!
          </b>
          <br />
          <br />
          <img src={loadGif} alt="" />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </h2>
      );
    } else {
      lang = eng;
      loadingContent = (
        <h2 style={{ textAlign: 'center' }}>
          Loading... <br />
          <b style={{ color: 'red' }}>You will soon be redirected. Please do not leave this page!</b>
          <br />
          <br />
          <img src={loadGif} alt="" />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </h2>
      );
    }
    const { nume, prenume, email, telefon, titluri, orasul, adresa, codPostal, club } = this.state; // "tara" was also part of this deconstruct

    if (!this.state.loading)
      content = (
        <form className="registration-form">
  
          <p className="details">
            {lang.registration.description}
            <br />
            <div style={{ display: this.state.showSections ? 'block' : 'none' }}>
              <span style={{ color: 'red', fontWeight: 'bold' }}>{lang.registration.attention} </span>
              {lang.registration.description2}
            </div>
          </p>
          <div className="form-container">
            <div className="col" style={{ display: this.state.showSections ? 'none' : 'block' }}>
              <br />
              <br />
              <h3 style={{ textAlign: 'center', display: this.state.showSections ? 'none' : 'block' }}>
                {lang.registration.form.personal_data}
                <span style={{ color: 'red' }}>*</span>
              </h3>
              <div className="personal-data" style={{ display: this.state.showSections ? 'none' : 'flex' }}>
                <div>
                  <br />
                  <br />
                  <input
                    type="text"
                    placeholder={lang.registration.form.first_name}
                    name="prenume"
                    onChange={this.changeHandler}
                    required
                    value={prenume}
                  />
                  <input
                    type="text"
                    placeholder={lang.registration.form.last_name}
                    name="nume"
                    onChange={this.changeHandler}
                    required
                    value={nume}
                  />

                  <input
                    type="text"
                    placeholder={lang.registration.form.email}
                    name="email"
                    onChange={this.changeHandler}
                    required
                    value={email}
                  />
                  <input
                    type="text"
                    placeholder={lang.registration.form.phone}
                    name="telefon"
                    onChange={this.changeHandler}
                    required
                    value={telefon}
                    style={{ marginBottom: '2.2em' }}
                  />

                  <div className="button-one">
                    <input type="checkbox" onChange={this.checkboxChanged} />

                    <span style={{ marginLeft: '1em' }} className="rules">
                      {lang.registration.form.check}{' '}
                      <a href="/regulament" style={{ color: 'red' }}>
                        {lang.registration.form.rules}
                      </a>
                      .
                    </span>
                    <br />
                    <br />

                    <br />
                    <button className="submit-button first" onClick={this.showSections} style={{color: 'red'}}>
                      {localStorage.getItem('lang') === 'ro' ? 'Încarcă fotografiile' : 'Upload photographs'}
                    </button>
                  </div>
                </div>
                <div>
                  <br />
                  <br />
                  <input
                    type="text"
                    placeholder={lang.registration.form.titles}
                    name="titluri"
                    onChange={this.changeHandler}
                    value={titluri}
                    class="registration-titles"
                  />
                  <input
                    type="text"
                    placeholder={lang.registration.form.club}
                    name="club"
                    onChange={this.changeHandler}
                    value={club}
                  />
                  <select name="tara" onChange={this.changeHandler}>
                    <option value="" selected>
                      {lang.registration.form.country}
                    </option>
                    {countries.map(country => (
                      <option value={country.name}>{country.name}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    placeholder={lang.registration.form.city}
                    name="orasul"
                    onChange={this.changeHandler}
                    required
                    value={orasul}
                  />
                  <input
                    type="text"
                    placeholder={lang.registration.form.address}
                    name="adresa"
                    onChange={this.changeHandler}
                    required
                    value={adresa}
                  />
                  <input
                    style={{ marginBottom: '3em' }}
                    type="text"
                    placeholder={lang.registration.form.postal_code}
                    name="codPostal"
                    onChange={this.changeHandler}
                    required
                    value={codPostal}
                  />
                  <div className="button-two">
                    <input type="checkbox" onChange={this.checkboxChanged} />

                    <span style={{ marginLeft: '1em' }} className="rules">
                      {lang.registration.form.check}{' '}
                      <a href="/regulament" style={{ color: 'red' }}>
                        {lang.registration.form.rules}
                      </a>
                      .
                    </span>
                    <br />
                    <br />

                    <br />
                    <button className="submit-button" onClick={this.showSections}>
                      {localStorage.getItem('lang') === 'ro' ? 'Încarcă fotografiile' : 'Upload photographs'}
                    </button>
                  </div>
                </div>
              </div>
            </div>

       
            <div className="col uploads" style={{ display: this.state.showSections ? 'flex' : 'none' }}>
              <br />

              {this.state.sections.map((section, index) => {
                return (
                  <div style={{ marginLeft: '1em', marginRight: '1em' }} className="upload-section">
                    <h3 className="h3-right" style={{ textAlign: 'center' }}>
                      {lang === ro ? section.nume_ro : section.nume_eng}
                    </h3>
                    <br />
                    <div className="file-input-container">
                      {/* <span>1.</span> */}
                      <div>
                        <input
                          type="file"
                          onChange={this.fileChanged}
                          name={`${index + 1}-1`}
                          accept="image/*"
                        />
                        <input
                          type="text"
                          placeholder={lang.registration.form.photo_name}
                          name={`${index + 1}-1`}
                          onChange={this.photoNameChanged}
                          value={this.state[`nume${index + 1}`][0]}
                        />
                      </div>
                    </div>

                    <div className="file-input-container">
                      {/* <span>2.</span> */}
                      <div>
                        <input
                          type="file"
                          onChange={this.fileChanged}
                          name={`${index + 1}-2`}
                          accept="image/*"
                        />
                        <input
                          type="text"
                          placeholder={lang.registration.form.photo_name}
                          name={`${index + 1}-2`}
                          onChange={this.photoNameChanged}
                          value={this.state[`nume${index + 1}`][1]}
                        />
                      </div>
                    </div>
                    <div className="file-input-container">
                      {/* <span>3.</span> */}

                      <div>
                        <input
                          type="file"
                          onChange={this.fileChanged}
                          name={`${index + 1}-3`}
                          accept="image/*"
                        />
                        <input
                          type="text"
                          placeholder={lang.registration.form.photo_name}
                          name={`${index + 1}-3`}
                          onChange={this.photoNameChanged}
                          value={this.state[`nume${index + 1}`][2]}
                        />
                      </div>
                    </div>
                    <div className="file-input-container">
                      {/* <span>4.</span> */}
                      <div>
                        <input
                          type="file"
                          onChange={this.fileChanged}
                          name={`${index + 1}-4`}
                          accept="image/*"
                        />
                        <input
                          type="text"
                          placeholder={lang.registration.form.photo_name}
                          name={`${index + 1}-4`}
                          onChange={this.photoNameChanged}
                          value={this.state[`nume${index + 1}`][3]}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
    
          <div style={{ display: this.state.showSections ? 'block' : 'none' }}>
              <button
                type="submit"
                className="submit-button"
                onClick={this.submitHandler}
                style={{ display: this.state.showSections ? 'block' : 'none', margin: '0 auto', color: 'red' }}
              >
                {lang.registration.form.submit}
              </button>
            </div>
            <span
            style={{
              color: 'red',
              fontWeight: '700',
              display: 'block',
              marginTop: '1.5em',
              textAlign: 'center',
              marginBottom: '1em',
              fontSize: '22px'
            }}
          >
            {this.state.errorMessage ? 
             localStorage.getItem('lang') === 'ro' ? 'Eroare: ' + this.state.errorMessage : 'Error: ' + this.state.errorMessage
             : ''}
          </span>
        </form>
      );
    else content = loadingContent;

    return (
      <div className="registration-form">
        {/* <h2 className="section-title" id="contact" style={{ paddingTop: '5em' }}>
          Contactați-ne
        </h2> */}
        {content}
      </div>
    );
  }
}

export default Form;
