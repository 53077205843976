import React, { Component } from 'react';
import axios from 'axios';
import Toolbar from '../../global/Toolbar/Toolbar';
import Footer from '../../global/Footer/Footer';
import SideDrawer from '../../global/SideDrawer/SideDrawer';
import Constrainter from '../../global/Constrainter/Constrainter';
import Backdrop from '../../global/Backdrop/Backdrop';
import AcceptedContent from './AcceptedContent';
import ro from './../../ro.json';
import eng from './../../eng.json';

class Accepted extends Component {
  state = {
    sideDrawerOpen: false,
    info: {}
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ info: response.data[0] });
        // console.log(this.state.info);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div className="Status">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter>
        <div className="content">
          {this.state.info.rezultate_afisate === '1' ? (
            <h2>{lang === ro ? 'Fotografii acceptate' : 'Accepted photographs'}</h2>
          ) : (
            ''
          )}
          <AcceptedContent />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Accepted;
