import React, { Component } from 'react';
import JuryPanelContent from './JuryPanelContent';

class JuryPanel extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount = () => {
    if (!localStorage.getItem('jury_token')) window.location = '/juriu/login';
  };

  render() {
    return (
      <div className="Status AdminPanel">
        {/* <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter> */}
        <div className="content">
          <h2>
            Bine ai venit, {localStorage.getItem('jury_name') + ' ' + localStorage.getItem('jury_surname')}
            <br />
            Welcome, {localStorage.getItem('jury_name') + ' ' + localStorage.getItem('jury_surname')}
          </h2>
          <JuryPanelContent />
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default JuryPanel;
