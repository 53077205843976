import React, { Component } from 'react';
import axios from 'axios';

class AwardedPhotosContent extends Component {
  state = {
    awardedPhotos: [],
    entries: [],
    nume_nou: ''
  };

  componentDidMount = () => {
    const data = new FormData();

    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));

    data.append('id_sectiune', this.props.id);
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_awarded_photos.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ awardedPhotos: response.data });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_admin_entries.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(response.data);
        this.setState({ entries: response.data });
        // console.log(this.state.entries);
      })
      .catch(e => {
        console.log(e);
      });
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state.nume_nou);
  };

  updateName = (oldName) => {
    // console.log(oldName);
    if (!this.state.nume_nou) return alert('Te rog să scrii numele fotografiei!');
    const oldNameArray = oldName.split('_');
    const newName = 
    `uploads/foto_${this.state.nume_nou.replaceAll(' ', '_')}_${oldNameArray[oldNameArray.length -  2]}_${oldNameArray[oldNameArray.length - 1]}`;
    // console.log(newName);
    const data = new FormData();
    data.append('old_name', oldName);
    data.append('new_name', newName);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/update_photo_name.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes! Pentru a vedea numele schimbat dați refresh la pagină!');
          // window.location.href = '/projects';
          // window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  render() {
    return (
      <div className="AdminPanelContent">
        {this.state.awardedPhotos.map(fotografie => {
          let name = fotografie.nume_fotografie.split('foto_')[1].replaceAll('_', ' ');
          let lastIndex = name.lastIndexOf(' ');
          name = name.substring(0, lastIndex);
          lastIndex = name.lastIndexOf(' ');
          name = name.substring(0, lastIndex);

          return (
            <div className="EntryContent">
              <div className="image-container">
                <img
                  style={{ height: '24em' }}
                  src={`https://salon.fotoclubproarad.ro/api/salon/${fotografie.nume_fotografie}`}
                  alt="FotoClubPro Arad"
                />
                <p style={{ cursor: 'initial' }}>{name}</p>
                <br />
                <form>
                  <input
                    type="text"
                    onChange={this.inputChange}
                    name="nume_nou"
                    placeholder="Modifică numele fotografiei"
                    style={{
                      padding: '0.2em',
                      marginRight: '0.4em',
                      width: '190px'
                    }}
                  />
                  <br />
                  <br />
                  <button
                    type="button"
                    onClick={() => this.updateName(fotografie.nume_fotografie)}
                    style={{
                      padding: '0.2em',
                      marginRight: '0.5em',
                      cursor: 'pointer',
                    }}
                  >
                    Trimite
                  </button>
                </form>
                <br/>
                <p style={{ cursor: 'initial' }}>
                  {/* Loc: <b>{fotografie.loc}</b>
                  <br /> */}
                  <b>{fotografie.titlu}</b> {/* Titlul premiului */}
                  <br />
                  {localStorage.getItem('token') ? (
                    <div>
                      ID concurent:
                      {this.state.entries.map(entry => {
                        if (entry.id === fotografie.id_participant) {
                          return ` ${entry.id}`;
                        } else return null;
                      })}
                      <br />
                      Nume:{' '}
                      <b>
                        {this.state.entries.map(entry => {
                          if (entry.id === fotografie.id_participant) {
                            return `${entry.prenume} ${entry.nume}`;
                          } else return null;
                        })}
                      </b>
                      <br />
                      <br />
                      Titluri fotografice:{' '}
                      <b>
                        {this.state.entries.map(entry => {
                          if (entry.id === fotografie.id_participant) {
                            return `${entry.titluri_fotografice}`;
                          } else return null;
                        })}
                      </b>
                      <br />
                      E-mail:{' '}
                      <b>
                        {this.state.entries.map(entry => {
                          if (entry.id === fotografie.id_participant) {
                            return `${entry.email}`;
                          } else return null;
                        })}
                      </b>
                      <br />
                      Foto club / Asociație:{' '}
                      {this.state.entries.map(entry => {
                        if (entry.id === fotografie.id_participant) {
                          return `${entry.club}`;
                        } else return null;
                      })}
                      <br />
                      Telefon:{' '}
                      {this.state.entries.map(entry => {
                        if (entry.id === fotografie.id_participant) {
                          return `${entry.telefon}`;
                        } else return null;
                      })}
                      <br />
                      Adresa:{' '}
                      {this.state.entries.map(entry => {
                        if (entry.id === fotografie.id_participant) {
                          return `${entry.adresa}`;
                        } else return null;
                      })}
                      <br />
                      Orașul:{' '}
                      {this.state.entries.map(entry => {
                        if (entry.id === fotografie.id_participant) {
                          return `${entry.oras}`;
                        } else return null;
                      })}
                      <br />
                      Țara:{' '}
                      {this.state.entries.map(entry => {
                        if (entry.id === fotografie.id_participant) {
                          return `${entry.tara}`;
                        } else return null;
                      })}
                      <br />
                      Cod poștal:{' '}
                      {this.state.entries.map(entry => {
                        if (entry.id === fotografie.id_participant) {
                          return `${entry.cod_postal}`;
                        } else return null;
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </p>
                <a
                  href={`https://salon.fotoclubproarad.ro/api/salon/${fotografie.nume_fotografie}`}
                  download={`${name}_ID${fotografie.id_participant}`}
                >
                  Descarcă fotografia /<br />
                  Download photograph
                </a>
              </div>
              <br />
              <br />
            </div>
          );
        })}
      </div>
    );
  }
}

export default AwardedPhotosContent;
