import React, { Component } from 'react';
import './Payment.css';
import axios from 'axios';
import Toolbar from '../../global/Toolbar/Toolbar';
import Footer from '../../global/Footer/Footer';
import SideDrawer from '../../global/SideDrawer/SideDrawer';
import Constrainter from '../../global/Constrainter/Constrainter';
import Backdrop from '../../global/Backdrop/Backdrop';
import ro from './../../ro.json';
import eng from './../../eng.json';
import { PayPalButton } from 'react-paypal-button-v2';

class Payment extends Component {
  state = {
    sideDrawerOpen: false,
    rules: [],
    email: '',
    prices: [],
    pret: '',
    info: {}
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount = () => {
    axios
    .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      this.setState({ info: response.data[0] });
      // console.log(this.state.info);
    })
    .catch(e => {
      console.log(e);
    });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_prices.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ prices: response.data, pret: response.data[0].suma });
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div className="Status Payment">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
        {this.state.info.plata_deschisa === '1' ? (
            
          
        <div>
        <h2>{lang === ro ? 'Plată' : 'Payment'}</h2>
        <br />
        <p style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}>
          {localStorage.getItem('lang') === 'ro'
            ? 'Dacă ați efectuat plata și nu ați apărut pe pagina Stare că ați plătit vă rugăm să ne contactați la concursfotoclubproarad@gmail.com!'
            : "If the payment was successful and you don't show up as paid on the Status page please contact us at concursfotoclubproarad@gmail.com."}
        </p>
        <br />
        <p style={{ textAlign: 'center', width: '50%', margin: '0 auto', color: 'red' }}>
          {localStorage.getItem('lang') === 'ro'
            ? 'Important: vă rugăm să completați câmpul din stânga paginii numai cu email-ul cu care v-ați înregistrat în concurs. Nu efectuați plata înainte de înregistrare!'
            : 'Important: please complete the field on the left only with the email you have used to register. Do not make the payment before registering!'}
        </p>
        <div
          className="content payment-content"
          style={{
            width: '50%',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>
            <br />
            <br />
            <input
              type="text"
              placeholder={
                localStorage.getItem('lang') === 'ro'
                  ? 'E-mail-ul cu care v-ați înscris în salon'
                  : 'The e-mail you have used to register'
              }
              name="email"
              onChange={this.changeHandler}
              required
              value={this.state.email}
            />
            <select name="pret" onChange={this.changeHandler}>
              {this.state.prices.map((price, index) => (
                <option value={price.suma} selected={index === 0 ? true : false}>
                  {localStorage.getItem('lang') === 'ro' ? price.nume_ro : price.nume_eng} - {price.suma} EUR
                </option>
              ))}
            </select>
          </div>
          <div style={{ zIndex: '0' }}>
            <PayPalButton
              amount={this.state.pret}
              // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
              onSuccess={(details, data) => {
                alert('Transaction completed by ' + details.payer.name.given_name);

                // OPTIONAL: Call your server to save the transaction
                const formData = new FormData();
                formData.append('email', this.state.email);
                return axios
                  .post('https://salon.fotoclubproarad.ro/api/admin/update_payment.php', formData, {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Client-ID aa4f70aa066eefe'
                    }
                    // Authorization: 'Bearer ' + varToken
                  })
                  .then(response => {
                    console.log(response);
                    if (response.data) {
                      alert('Success!');
                      window.location.href = '/status';
                    }
                  })
                  .catch(e => {
                    console.log(e.response);
                    console.log('Error');
                  });
              }}
              options={{
                // clientId: 'AUis9nzPl4P0J67TiYjSWk2Ba07_4S9DMmOPi-fey4BXDftYYLeq_fE4c460LH35a_tzrX2fV_Ve2Fo4'
                clientId: 'AWXEWpE2Ux4GiN2qjfzmQcZi6tV5mPiDT2yRbXwEWzBA8de8gS6p5U7NLjIVp8O4Ylo4HtEtrvZa4Pmc',
                currency: 'EUR'
              }}
            />
          </div>
        </div>

        </div>

        ): (
            <h2 style={{ textAlign: 'center', fontSize: '36px', fontWeight: '600' }}>
              {lang === ro
                ? `Înscrierile s-au încheiat la data de ${this.state.info.data_incheiere}`
                : `The registration ended on ${this.state.info.data_incheiere}`}
            </h2>
        )}

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Footer />
      </div>
    );
  }
}

export default Payment;
