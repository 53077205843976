import React, { Component } from 'react';
import './AdminLoginForm.css';
import axios from 'axios';

class AdminLoginForm extends Component {
  state = {
    password: ''
  };

  passwordChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount = () => {
    if (localStorage.getItem('token')) window.location = '/admin';
  };

  submitForm = () => {
    const data = new FormData();
    data.append('password', this.state.password);

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/login.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (!response.data) return alert('Parolă incorectă');
        localStorage.setItem('token', response.data);
        // window.location.href = '/projects';
        window.location = '/admin';
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  render() {
    return (
      <div className="AdminLoginForm">
        <form>
          <br />
          <input
            type="password"
            onChange={this.passwordChange}
            name="password"
            placeholder="Introdu parola"
          />
          <br />
          <br />
          <button type="button" onClick={this.submitForm}>
            Confirmă
          </button>
        </form>
      </div>
    );
  }
}
export default AdminLoginForm;
