import React, { Component } from 'react';
import axios from 'axios';

class InfoContent extends Component {
  state = {
    info: [''],
    data_incepere: '',
    data_incheiere: '',
    data_afisare_rezultate: '',
    nota: '',
    premii: [],
    premiu_nou: '',
    regulament_eng: undefined,
    regulament_ro: undefined,
    coduri_patronaje: '',
    nume_fotografie_acasa: '',
    fotografie_noua_acasa: undefined,
    banner_acasa: undefined,
    homePhotos: [],
    rules_title_ro: '',
    rules_title_eng: '',
    rules_text_ro: '',
    rules_text_eng: '',
    rules: [],
    payment_name_eng: '',
    payment_name_ro: '',
    payment_price: '',
    prices: [],
    fotografie_noua_regulament: undefined,
    rulesPhotos: [],
    special_prize_name: '',
    special_prize_winner: '',
    special_prizes: [],
    update_rule_ro: '',
    update_rule_eng: ''
  };

  fileChanged = event => {
    this.setState({
      fotografie_noua_acasa: event.target.files[0]
    });
  };

  fileChangedRules = event => {
    this.setState({
      fotografie_noua_regulament: event.target.files[0]
    });
  };

  fileChangedBanner = event => {
    this.setState({
      banner_acasa: event.target.files[0]
    });
  };

  componentDidMount = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ info: response.data });
        // console.log(response.data);
        // console.log(this.state);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_home_photos.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ homePhotos: response.data });
        // console.log(response.data);
        // console.log(this.state.homePhotos);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_rules_photos.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ rulesPhotos: response.data });
        // console.log(response.data);
        // console.log(this.state.homePhotos);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_prize_list.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ premii: response.data });
        // console.log(response.data);
        // console.log(this.state.premii);
      })
      .catch(e => {
        console.log(e);
      });
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_rules.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ rules: response.data });
        // console.log(response.data);
        // console.log(this.state.rules);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_prices.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ prices: response.data });
        // console.log(response.data);
        // console.log(this.state.rules);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_special_prizes.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ special_prizes: response.data });
        // console.log(response.data);
        // console.log(this.state.rules);
      })
      .catch(e => {
        console.log(e);
      });
  };

  updateDataIncepere = () => {
    if (!this.state.data_incepere) return alert('Te rog să alegi data!');
    const data = new FormData();
    data.append('data', this.state.data_incepere);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/update_data_incepere.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  updateDataIncheiere = () => {
    if (!this.state.data_incheiere) return alert('Te rog să alegi data!');
    const data = new FormData();
    data.append('data', this.state.data_incheiere);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/update_data_incheiere.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  updateDataAfisareRezultate = () => {
    if (!this.state.data_afisare_rezultate) return alert('Te rog să alegi data!');
    const data = new FormData();
    data.append('data', this.state.data_afisare_rezultate);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/update_data_afisare_rezultate.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleInscrieriDeschise = () => {
    let r = window.confirm('Sigur doriți să faceți asta?');
    if (r === true) {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/toggle_inscrieri_deschise.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  togglePlataDeschisa = () => {
    let r = window.confirm('Sigur doriți să faceți asta?');
    if (r === true) {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/toggle_plata_deschisa.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  toggleJurizariDeschise = () => {
    let r = window.confirm('Sigur doriți să faceți asta?');
    if (r === true) {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/toggle_jurizari_deschise.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  toggleRezultateAfisate = () => {
    let r = window.confirm('Sigur doriți să faceți asta?');
    if (r === true) {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/toggle_rezultate_afisate.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  updateNotaMaxima = () => {
    if (!this.state.nota) return alert('Te rog să alegi nota!');
    const data = new FormData();
    data.append('nota', this.state.nota);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/update_nota_maxima.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  newPrize = () => {
    if (!this.state.premiu_nou) return alert('Te rog să scrii numele premiului!');
    const data = new FormData();
    data.append('titlu_premiu', this.state.premiu_nou);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_prize_to_list.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  deletePrize = id => {
    const data = new FormData();
    data.append('id', id);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/delete_prize_from_list.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChangedRo = event => {
    this.setState({
      regulament_ro: event.target.files[0]
    });
  };

  fileChangedEng = event => {
    this.setState({
      regulament_eng: event.target.files[0]
    });
  };

  uploadRulesRo = event => {
    event.preventDefault();
    if (!this.state.regulament_ro) return alert('Te rog să încarci un PDF cu regulamentul!');
    const data = new FormData();
    data.append('regulament_ro', this.state.regulament_ro);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/upload_rules_ro.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  uploadRulesEng = event => {
    event.preventDefault();
    if (!this.state.regulament_eng) return alert('Te rog să încarci un PDF cu regulamentul!');
    const data = new FormData();
    data.append('regulament_eng', this.state.regulament_eng);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/upload_rules_eng.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  updateCoduri = () => {
    if (!this.state.coduri_patronaje) return alert('Te rog să scrii codurile!');
    const data = new FormData();
    data.append('coduri_patronaje', this.state.coduri_patronaje);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/update_coduri_patronaje.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  uploadPhoto = event => {
    event.preventDefault();
    // console.log(reqBody);
    if (!this.state.nume_fotografie_acasa) return alert('Te rog să completezi detaliile fotografiei!');

    // console.log(fotografii);
    const data = new FormData();
    data.append('nume_fotografie', this.state.nume_fotografie_acasa);
    data.append('fotografie', this.state.fotografie_noua_acasa);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/salon/upload_home_photo.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        console.log(response.data);
        if (response.data) {
          alert('Succes!');
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  uploadPhotoRules = event => {
    event.preventDefault();
    // console.log(reqBody);

    // console.log(fotografii);
    const data = new FormData();
    data.append('fotografie', this.state.fotografie_noua_regulament);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/salon/upload_rules_photo.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        console.log(response.data);
        if (response.data) {
          alert('Succes!');
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  uploadPhotoBanner = event => {
    event.preventDefault();

    // console.log(fotografii);
    const data = new FormData();
    data.append('fotografie', this.state.banner_acasa);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/salon/upload_home_banner.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        console.log(response.data);
        if (response.data) {
          alert('Succes!');
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  deleteHomePhoto = id => {
    let r = window.confirm('Sigur doriți să ștergeți fotografia?');
    if (r === true) {
      const data = new FormData();
      data.append('id_fotografie', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_home_photo.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  reset = () => {
    let r = window.confirm('Sigur doriți să resetați concursul?');
    if (r === true) {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/salon/reset.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  deleteRulesPhoto = id => {
    let r = window.confirm('Sigur doriți să ștergeți fotografia?');
    if (r === true) {
      const data = new FormData();
      data.append('id_fotografie', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_rules_photo.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  addRule = () => {
    const data = new FormData();
    data.append('titlu_ro', this.state.rules_title_ro);
    data.append('titlu_eng', this.state.rules_title_eng);
    data.append('text_ro', this.state.rules_text_ro);
    data.append('text_eng', this.state.rules_text_eng);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_rule.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  deleteRule = id => {
    let r = window.confirm('Sigur doriți să ștergeți paragraful?');
    if (r === true) {
      const data = new FormData();
      data.append('id', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_rule.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };
  deletePrice = id => {
    let r = window.confirm('Sigur doriți să ștergeți prețul?');
    if (r === true) {
      const data = new FormData();
      data.append('id', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_price.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  deleteSpecialPrize = id => {
    let r = window.confirm('Sigur doriți să ștergeți prețul?');
    if (r === true) {
      const data = new FormData();
      data.append('id', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_special_prize.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  addPayment = () => {
    const data = new FormData();
    data.append('nume_ro', this.state.payment_name_ro);
    data.append('nume_eng', this.state.payment_name_eng);
    data.append('suma', this.state.payment_price);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_price.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  addSpecialPrize = () => {
    const data = new FormData();
    data.append('nume', this.state.special_prize_name);
    data.append('castigator', this.state.special_prize_winner);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_special_prize.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  updateRule = (lang, id) => {
    const data = new FormData();
    data.append('id', id);
    data.append('token', localStorage.getItem('token'));

    let url = '';

    if (lang === 'ro') {
      url = 'https://salon.fotoclubproarad.ro/api/admin/update_rule_ro.php';
      data.append('new_rule', this.state.update_rule_ro);
    } else {
      url = 'https://salon.fotoclubproarad.ro/api/admin/update_rule_eng.php';
      data.append('new_rule', this.state.update_rule_eng);
    }

    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes! Verificați modificările pe pagina de regulament a salonului!');
          // window.location.href = '/projects';
          // window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  }; 

  render() {
    return (
      <div className="SectionsContent SectionContent EntryContent">
        <br />
        <h3>
          Dată începere jurizare (judging): {this.state.info[0].data_incepere}
          <br />
          <br />
          Modificare:
          <form>
            <br />
            <input type="date" onChange={this.changeHandler} name="data_incepere" />
            <br />
            <button type="button" onClick={this.updateDataIncepere}>
              Confirmă
            </button>
          </form>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          Dată încheiere salon: {this.state.info[0].data_incheiere}
          <br />
          <br />
          Modificare:
          <form>
            <br />
            <input type="date" onChange={this.changeHandler} name="data_incheiere" />
            <br />
            <button type="button" onClick={this.updateDataIncheiere}>
              Confirmă
            </button>
          </form>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          Dată afișare rezultate: {this.state.info[0].data_afisare_rezultate}
          <br />
          <br />
          Modificare:
          <form>
            <br />
            <input type="date" onChange={this.changeHandler} name="data_afisare_rezultate" />
            <br />
            <button type="button" onClick={this.updateDataAfisareRezultate}>
              Confirmă
            </button>
          </form>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          Înscrierea deschisă (daca da, atunci fotografii se pot înscrie în formular):{' '}
          {this.state.info[0].inscrieri_deschise === '1' ? 'da' : 'nu'}
          <br />
          <br />
          <span style={{ fontSize: '17px', cursor: 'pointer' }} onClick={this.toggleInscrieriDeschise}>
            {this.state.info[0].inscrieri_deschise === '1' ? 'Închide înscrierile' : 'Deschide înscrierile'}
          </span>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          Plata deschisă (daca da, atunci fotografii pot realiza plata):{' '}
          {this.state.info[0].plata_deschisa === '1' ? 'da' : 'nu'}
          <br />
          <br />
          <span style={{ fontSize: '17px', cursor: 'pointer' }} onClick={this.togglePlataDeschisa}>
            {this.state.info[0].plata_deschisa === '1' ? 'Închide plata' : 'Deschide plata'}
          </span>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          Jurizarea deschisă (daca da, atunci jurații pot da note fotografiilor):{' '}
          {this.state.info[0].jurizari_deschise === '1' ? 'da' : 'nu'}
          <br />
          <br />
          <span style={{ fontSize: '17px', cursor: 'pointer' }} onClick={this.toggleJurizariDeschise}>
            {this.state.info[0].jurizari_deschise === '1' ? 'Închide jurizările' : 'Deschide jurizările'}
          </span>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          Rezultate afișate (daca da, atunci rezultatele sunt afișate pe pagina de rezultate iar pagina Status se va ascunde):{' '}
          {this.state.info[0].rezultate_afisate === '1' ? 'da' : 'nu'}
          <br />
          <br />
          <span style={{ fontSize: '17px', cursor: 'pointer' }} onClick={this.toggleRezultateAfisate}>
            {this.state.info[0].rezultate_afisate === '1' ? 'Ascunde rezultatele' : 'Afișează rezultatele'}
          </span>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          Nota maximă (nota maximă care poate fi acordată unei fotografii): {this.state.info[0].nota_maxima}
          <br />
          <br />
          Modificare:
          <form>
            <br />
            <select name="nota" onChange={this.changeHandler}>
              <option value="" selected>
                {this.state.info[0].nota_maxima}
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <br />
            <button type="button" onClick={this.updateNotaMaxima}>
              Confirmă
            </button>
          </form>
        </h3>
        <br />
        <hr />
        <br />
        <h3>
          <u>Lista premiilor</u>
          <p>
            Vă rog să adăugați premiile începând cu cel mai important si terminând cu cel mai puțin important!
          </p>
          <br />
          <br />
          {this.state.premii.map(premiu => {
            return (
              <div>
                <span>{premiu.titlu}</span>
                <br />
                <br />
                <span
                  style={{ fontSize: '13px', color: 'red', cursor: 'pointer' }}
                  onClick={() => this.deletePrize(premiu.id)}
                >
                  Șterge premiul
                </span>
                <br />
                <br />
              </div>
            );
          })}
          <br />
          <br />
        </h3>
        <form>
          <h4>Premiu nou</h4>
          <input
            type="text"
            onChange={this.inputChange}
            name="premiu_nou"
            placeholder="Titlul premiului"
            style={{
              padding: '0.2em',
              marginRight: '0.4em',
              width: '300px'
            }}
          />
          <button
            type="button"
            onClick={this.newPrize}
            style={{
              padding: '0.2em',
              marginRight: '0.5em',
              cursor: 'pointer',
              width: '150px'
            }}
          >
            Trimite
          </button>
        </form>
        {/* <p>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            Atenție! Nu adăuga o secțiune după începerea concursului!
          </span>
        </p> */}
        <br />
        <hr />
        <br />
        <h4>Coduri patronaje</h4>
        <p style={{ whiteSpace: 'pre-wrap' }}>{this.state.info[0].coduri_patronaje}</p>
        <br />
        <br />
        <form>
          <h4>Modificare coduri patronaje</h4>
          <p>Te rog să introduci fiecare cod pe o linie nouă de text!</p>
          <br />
          <textarea
            type="text"
            onChange={this.inputChange}
            name="coduri_patronaje"
            placeholder="Coduri patronaje"
            style={{
              padding: '0.2em',
              marginRight: '0.4em',
              width: '300px'
            }}
          />
          <br />
          <button
            type="button"
            onClick={this.updateCoduri}
            style={{
              padding: '0.2em',
              marginRight: '0.5em',
              cursor: 'pointer',
              width: '150px'
            }}
          >
            Trimite
          </button>
        </form>
        <br />
        <hr />
        <br />
        <h4>Fotografiile din slideshow-ul de pe pagina Acasă</h4>
        <br />
        <h4>Încarcă o fotografie nouă</h4>
        {/* <p>
          Recomandabil: la detalii fotografie păstrează următoarea formă: Prenume Nume Titluri Fotografice
          (Țara) - Premiu (Secțiune) - Nume Fotografie
        </p> */}
        <p>Detalii fotografie se referă la numele fotografului, titlul fotografiei etc.</p>
        <div className="AdminLoginForm" style={{ marginTop: '0' }}>
          <form>
            <br />
            <input type="file" onChange={this.fileChanged} name="fotografie_noua_acasa" accept="image/*" />
            <br />
            <br />
            <input
              type="text"
              onChange={this.changeHandler}
              name="nume_fotografie_acasa"
              placeholder="Detalii fotografie"
            />

            <br />
            <br />
            <button type="button" onClick={this.uploadPhoto}>
              Confirmă
            </button>
          </form>
        </div>
        {/* Ionel Onofraș - The mother's shelter - Romania */}
        <div style={{ display: 'flex', flexFlow: 'wrap' }}>
          {/* <>aici vin pozele */}
          {this.state.homePhotos.map(photo => {
            return (
              <div style={{ marginRight: '1em' }}>
                <br />
                <img
                  src={`https://salon.fotoclubproarad.ro/api/salon/${photo.nume}`}
                  style={{ height: '12em' }}
                  alt="FotoClubPro Arad"
                />
                <p style={{ width: '300px', cursor: 'initial' }}>{photo.detalii}</p>
                <span
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => this.deleteHomePhoto(photo.id)}
                >
                  Șterge poza
                </span>
              </div>
            );
          })}
        </div>
        <br />
        <hr />
        <br />
        <h4>
          Încarcă fotografia cu banner-ul de pe pagina Acasă. Această fotografie trebuie să fie în format
          jpeg!
        </h4>
        <div className="AdminLoginForm" style={{ marginTop: '0' }}>
          <form>
            <br />
            <input type="file" onChange={this.fileChangedBanner} name="banner_acasa" accept="image/*" />

            <br />
            <br />
            <button type="button" onClick={this.uploadPhotoBanner}>
              Confirmă
            </button>
          </form>
        </div>
        <br />
        <hr />
        <br />
        <form>
          <h4>Modificare regulament</h4>
          <p>Pentru a introduce un nou paragraf în regulament completează formularul de mai jos!</p>
          <br />
          <input
            type="text"
            onChange={this.changeHandler}
            name="rules_title_ro"
            placeholder="Titlu paragraf în limba română"
            style={{ width: '300px' }}
          />
          <br />
          <br />
          <input
            type="text"
            onChange={this.changeHandler}
            name="rules_title_eng"
            placeholder="Titlu paragraf în limba engleză"
            style={{ width: '300px' }}
          />
          <br />
          <br />
          <textarea
            type="text"
            onChange={this.inputChange}
            name="rules_text_ro"
            placeholder="Paragraf regulament în limba română"
            style={{
              padding: '0.2em',
              marginRight: '0.4em',
              width: '300px'
            }}
          />
          <br />
          <br />
          <textarea
            type="text"
            onChange={this.inputChange}
            name="rules_text_eng"
            placeholder="Paragraf regulament în limba engleză"
            style={{
              padding: '0.2em',
              marginRight: '0.4em',
              width: '300px'
            }}
          />
          <br />
          <button
            type="button"
            onClick={this.addRule}
            style={{
              padding: '0.2em',
              marginRight: '0.5em',
              cursor: 'pointer',
              width: '150px'
            }}
          >
            Trimite
          </button>
        </form>
        <br />
        <h4>Paragrafe regulament:</h4>
        <br />
        {this.state.rules.map(rule => {
          return (
            <div>
              <h4>
                {rule.titlu_ro} / {rule.titlu_eng}
              </h4>
              <br />
              <form>
                <textarea
                  type="text"
                  onChange={this.inputChange}
                  name="update_rule_ro"
                  placeholder="Modifică paragraful în limba română"
                  style={{
                  padding: '0.2em',
                  marginRight: '0.4em',
                  width: '300px'
                  }}
                />
                <br />
                <button
                  type="button"
                  onClick={() => this.updateRule('ro', rule.id)}
                  style={{
                  padding: '0.2em',
                  marginRight: '0.5em',
                  cursor: 'pointer',
                  width: '150px'
                  }}
                >
                Trimite
                </button>
              </form>
              <br />
              <form>
                <textarea
                  type="text"
                  onChange={this.inputChange}
                  name="update_rule_eng"
                  placeholder="Modifică paragraful în limba engleză"
                  style={{
                  padding: '0.2em',
                  marginRight: '0.4em',
                  width: '300px'
                  }}
                />
                <br />
                <button
                  type="button"
                  onClick={() => this.updateRule('eng', rule.id)}
                  style={{
                  padding: '0.2em',
                  marginRight: '0.5em',
                  cursor: 'pointer',
                  width: '150px'
                  }}
                >
                Trimite
                </button>
              </form>
              <br />
              <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.deleteRule(rule.id)}>
                Șterge paragraful
              </span>
              <br />
              <br />
            </div>
          );
        })}
        <br />
        <hr />
        <br />
        <h4>Fotografiile de la începutul paginii Regulament (fotografii patronaje)</h4>
        <br />
        <h4>Încarcă o fotografie nouă</h4>
        {/* <p>
          Recomandabil: la detalii fotografie păstrează următoarea formă: Prenume Nume Titluri Fotografice
          (Țara) - Premiu (Secțiune) - Nume Fotografie
        </p> */}
        <div className="AdminLoginForm" style={{ marginTop: '0' }}>
          <form>
            <br />
            <input
              type="file"
              onChange={this.fileChangedRules}
              name="fotografie_noua_regulament"
              accept="image/*"
            />

            <br />
            <br />
            <button type="button" onClick={this.uploadPhotoRules}>
              Confirmă
            </button>
          </form>
        </div>
        {/* Ionel Onofraș - The mother's shelter - Romania */}
        <div style={{ display: 'flex', flexFlow: 'wrap' }}>
          {/* <>aici vin pozele */}
          {this.state.rulesPhotos.map(photo => {
            return (
              <div style={{ marginRight: '1em' }}>
                <br />
                <img
                  src={`https://salon.fotoclubproarad.ro/api/salon/${photo.nume}`}
                  style={{ height: '12em' }}
                  alt="FotoClubPro Arad"
                />
                <p style={{ width: '300px', cursor: 'initial' }}>{photo.detalii}</p>
                <span
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => this.deleteRulesPhoto(photo.id)}
                >
                  Șterge poza
                </span>
              </div>
            );
          })}
        </div>
        <br />
        <hr />
        <br />
        <form>
          <h4>Modificare plată</h4>
          <p>
            Pentru a introduce un preț nou în lista de pe pagina Plată, completează formularul de mai jos!
          </p>
          <br />
          <input
            type="text"
            onChange={this.changeHandler}
            name="payment_name_ro"
            placeholder="Nume plată în limba română (ex: 2 sectiuni)"
            style={{ width: '400px' }}
          />
          <br />
          <br />
          <input
            type="text"
            onChange={this.changeHandler}
            name="payment_name_eng"
            placeholder="Nume plată în limba engleză (ex: 2 sections)"
            style={{ width: '400px' }}
          />
          <br />
          <br />
          <input
            type="text"
            onChange={this.changeHandler}
            name="payment_price"
            placeholder="Sumă în EUR (ex: 15)"
            style={{ width: '200px' }}
          />
          <br />
          <br />

          <button
            type="button"
            onClick={this.addPayment}
            style={{
              padding: '0.2em',
              marginRight: '0.5em',
              cursor: 'pointer',
              width: '150px'
            }}
          >
            Trimite
          </button>
        </form>
        <br />
        <h4>Listă prețuri:</h4>
        <br />
        {this.state.prices.map(price => {
          return (
            <div>
              <h4>
                {price.nume_ro} / {price.nume_eng} - {price.suma} EUR
              </h4>
              <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.deletePrice(price.id)}>
                Șterge prețul
              </span>
              <br />
              <br />
            </div>
          );
        })}
        <br />
        <hr />
        <br />
        <form>
          <h4>Premii speciale (de ex. Best Club)</h4>
          <p>Pentru a introduce un premiu completează formularul adaugând și câstigătorul!</p>
          <br />
          <input
            type="text"
            onChange={this.changeHandler}
            name="special_prize_name"
            placeholder="Nume premiu (ex. Best Club)"
            style={{ width: '400px' }}
          />
          <br />
          <br />
          <input
            type="text"
            onChange={this.changeHandler}
            name="special_prize_winner"
            placeholder="Câștigător"
            style={{ width: '400px' }}
          />
          <br />
          <br />

          <button
            type="button"
            onClick={this.addSpecialPrize}
            style={{
              padding: '0.2em',
              marginRight: '0.5em',
              cursor: 'pointer',
              width: '150px'
            }}
          >
            Trimite
          </button>
        </form>
        <br />
        <h4>Listă premii:</h4>
        <br />
        {this.state.special_prizes.map(prize => {
          return (
            <div>
              <h4>
                {prize.nume} - {prize.castigator}
              </h4>
              <span
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => this.deleteSpecialPrize(prize.id)}
              >
                Șterge premiul
              </span>
              <br />
              <br />
            </div>
          );
        })}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <hr />
        <br />
        <h4>Resetează concursul:</h4>
        <p style={{ color: 'red', cursor: 'initial' }}>
          Atenție! Resetarea concursului înseamnă: ștergerea tuturor fotografiilor, ștergerea listei cu
          fotografiile premiate și cele acceptate, ștergerea conturilor juriului, ștergerea notelor acordate
          fotografiilor și ștergerea tuturor participanților din baza de date!!!
        </p>
        <br />
        <br />
        <span
          style={{ color: 'red', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline' }}
          onClick={this.reset}
        >
          Resetează
        </span>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* <h4>Încarcă regulamentul în limba română</h4>
        <form>
          <br />
          <input type="file" onChange={this.fileChangedRo} name="regulament_ro" accept="application/pdf" />

          <br />
          <br />
          <button type="button" onClick={this.uploadRulesRo}>
            Confirmă
          </button>
        </form>
        <br />
        <hr />
        <br />
        <h4>Încarcă regulamentul în limba engleză</h4>
        <form>
          <br />
          <input type="file" onChange={this.fileChangedEng} name="regulament_eng" accept="application/pdf" />

          <br />
          <br />
          <button type="button" onClick={this.uploadRulesEng}>
            Confirmă
          </button>
        </form> */}
      </div>
    );
  }
}

export default InfoContent;
