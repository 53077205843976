import React, { Component } from 'react';
import axios from 'axios';
import AwardedPhotosContent from './AwardedPhotosContent';

class AwardedPhotos extends Component {
  state = {
    sideDrawerOpen: false,
    nume_ro: '',
    nume_eng: '',
    sections: []
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount = () => {
    if (!localStorage.getItem('token') && !localStorage.getItem('jury_token'))
      window.location = '/admin/login';

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    // let backdrop;
    return (
      <div className="Status AdminPanel">
        {/* <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter> */}
        <div className="content">
          <h2>
            Fotografiile premiate pentru secțiunea{' '}
            <span style={{ color: 'red' }}>
              {this.state.sections.map(section => {
                if (section.id === this.props.match.params.id) return section.nume_ro + ' ';
                return ''; // Added later, remove if buggy
              })}
            </span>
            / Awarded photographs for section{' '}
            <span style={{ color: 'red' }}>
              {this.state.sections.map(section => {
                if (section.id === this.props.match.params.id) return section.nume_eng;
                return ''; // Added later, remove if buggy
              })}
            </span>
          </h2>
          <AwardedPhotosContent id={this.props.match.params.id} />
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default AwardedPhotos;
