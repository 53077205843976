import React, { Component } from 'react';
import axios from 'axios';
import './EntryContent.css';

class EntryContent extends Component {
  state = {
    entries: [],
    sections: [],
    nume_fotografie: '',
    select_section_id: '',
    fotografie_noua: undefined,
    updatePhotoNameInput: '',
    updateEntryLastNameInput: '',
    updateEntryFirstNameInput: '',
    updateEntryEmailInput: '',
    updateEntryPhoneInput: '',
    updateEntryTitlesInput: '',
    updateEntryClubInput: '',
    updateEntryCountryInput: '',
    updateEntryCityInput: '',
    updateEntryAddressInput: '',
    updateEntryPostalCodeInput: ''
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_admin_entries.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(this.state);
        this.setState({ entries: response.data });
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  deleteEntry = () => {
    let r = window.confirm('Sigur doriți să ștergeți concurentul?');
    if (r === true) {
      const data = new FormData();
      data.append('id', this.props.id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_entry.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.href = '/admin/inscrisi';
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  deletePhoto = id => {
    let r = window.confirm('Sigur doriți să ștergeți fotografia?');
    if (r === true) {
      const data = new FormData();
      data.append('id_fotografie', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_entry_photo.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  togglePayment = () => {
    let r = window.confirm('Sigur doriți să schimbați status-ul de plată?');
    if (r === true) {
      const data = new FormData();
      data.append('id', this.props.id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/toggle_payment.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChanged = event => {
    this.setState({
      fotografie_noua: event.target.files[0]
    });
  };

  uploadPhoto = event => {
    event.preventDefault();
    const reqBody = { ...this.state };
    // console.log(reqBody);
    if (!reqBody.select_section_id || !reqBody.fotografie_noua || !reqBody.nume_fotografie)
      return alert('Te rog să completezi toate câmpurile pentru a adăuga o fotografie nouă!');

    // console.log(fotografii);
    if (reqBody.fotografie_noua.size / 1024 / 1024 > 6)
      // limita de 5MB per poza
      return alert('Fotografia poate avea maximum 6MB!');
    const data = new FormData();
    data.append(
      'nume_fotografie',
      `foto_${this.state.nume_fotografie.replace(/\s/g, '_')}_${this.state.select_section_id}`
    );
    data.append('id_participant', this.props.id);
    data.append('id_sectiune', this.state.select_section_id);
    data.append('fotografie', this.state.fotografie_noua);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/salon/upload_entry_photo.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  updatePhotoName = (oldName) => {
    // console.log(oldName);
    if (!this.state.updatePhotoNameInput) return alert('Te rog să scrii numele fotografiei!');
    const oldNameArray = oldName.split('_');
    const newName = 
    `uploads/foto_${this.state.updatePhotoNameInput.replaceAll(' ', '_')}_${oldNameArray[oldNameArray.length -  2]}_${oldNameArray[oldNameArray.length - 1]}`;
    // console.log(newName);
    const data = new FormData();
    data.append('old_name', oldName);
    data.append('new_name', newName);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/update_photo_name.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  updateEntryField = (field) => {
    let url = '';

    const data = new FormData();
    data.append('id', this.props.id);
    data.append('token', localStorage.getItem('token'));

    if (field === 'lastName')
    {
      if (!this.state.updateEntryLastNameInput) return alert('Te rog să scrii numele participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_last_name.php';
      data.append('new_last_name', this.state.updateEntryLastNameInput);
    }
    else if (field === "firstName")
    {
      if (!this.state.updateEntryFirstNameInput) return alert('Te rog să scrii prenumele participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_first_name.php';
      data.append('new_first_name', this.state.updateEntryFirstNameInput);
    }
    else if (field === 'email')
    {
      if (!this.state.updateEntryEmailInput) return alert('Te rog să scrii e-mail-ul participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_email.php';
      data.append('new_email', this.state.updateEntryEmailInput);
    }
    else if (field === 'phone')
    {
      if (!this.state.updateEntryPhoneInput) return alert('Te rog să scrii numărul de telefon al participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_phone.php';
      data.append('new_phone', this.state.updateEntryPhoneInput);
    }
    else if (field === 'titles')
    {
      if (!this.state.updateEntryTitlesInput) return alert('Te rog să scrii titlurile fotografice ale participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_titles.php';
      data.append('new_titles', this.state.updateEntryTitlesInput);
    }
    else if (field === 'club')
    {
      if (!this.state.updateEntryClubInput) return alert('Te rog să scrii foto clubul participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_club.php';
      data.append('new_club', this.state.updateEntryClubInput);
    }
    else if (field === 'country')
    {
      if (!this.state.updateEntryCountryInput) return alert('Te rog să scrii țara participantului (în engleză)!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_country.php';
      data.append('new_country', this.state.updateEntryCountryInput);
    }
    else if (field === 'city')
    {
      if (!this.state.updateEntryCityInput) return alert('Te rog să scrii orașul participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_city.php';
      data.append('new_city', this.state.updateEntryCityInput);
    }
    else if (field === 'address')
    {
      if (!this.state.updateEntryAddressInput) return alert('Te rog să scrii adresa participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_address.php';
      data.append('new_address', this.state.updateEntryAddressInput);
    }
    else if (field === 'postalCode')
    {
      if (!this.state.updateEntryPostalCodeInput) return alert('Te rog să scrii codul poștal al participantului!');

      url = 'https://salon.fotoclubproarad.ro/api/admin/update_entry_postal_code.php';
      data.append('new_postal_code', this.state.updateEntryPostalCodeInput);
    }

    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          alert('Succes!');
          // window.location.href = '/projects';
          window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  render() {
    return (
      <div className="SectionsContent SectionContent EntryContent">
        {this.state.entries.map(entry => {
          if (entry.id === this.props.id)
            return (
              <div className="entry-details">
                <h2>
                  {entry.prenume} {entry.nume}
                </h2>
                <h3>
                  Nume: <br />
                  <span style={{ fontWeight: '400' }}>{entry.nume}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryLastNameInput"
                      placeholder="Modifică numele participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('lastName')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Prenume: <br />
                  <span style={{ fontWeight: '400' }}>{entry.prenume}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryFirstNameInput"
                      placeholder="Modifică prenumele participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('firstName')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  E-mail: <br />
                  <span style={{ fontWeight: '400' }}>{entry.email}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryEmailInput"
                      placeholder="Modifică e-mail-ul participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('email')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Telefon: <br />
                  <span style={{ fontWeight: '400' }}>{entry.telefon}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryPhoneInput"
                      placeholder="Modifică numărul de telefon al participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('phone')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Titluri fotografice: <br />
                  <span style={{ fontWeight: '400' }}>{entry.titluri_fotografice}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryTitlesInput"
                      placeholder="Modifică titlurile fotografice ale participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('titles')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Foto club / Asociație: <br />
                  <span style={{ fontWeight: '400' }}>{entry.club}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryClubInput"
                      placeholder="Modifică foto clubul participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('club')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Țara: <br />
                  <span style={{ fontWeight: '400' }}>{entry.tara}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryCountryInput"
                      placeholder="Modifică țara participantului (în engleză)"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('country')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Oraș: <br />
                  <span style={{ fontWeight: '400' }}>{entry.oras}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryCityInput"
                      placeholder="Modifică orașul participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('city')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Adresa: <br />
                  <span style={{ fontWeight: '400' }}>{entry.adresa}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryAddressInput"
                      placeholder="Modifică adresa participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('address')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Cod poștal: <br />
                  <span style={{ fontWeight: '400' }}>{entry.cod_postal}</span>
                  <br />
                  <br />
                  <form>
                    <input
                      type="text"
                      onChange={this.inputChange}
                      name="updateEntryPostalCodeInput"
                      placeholder="Modifică codul poștal participantului"
                      style={{
                        padding: '0.2em',
                        marginRight: '0.4em',
                        width: '300px'
                      }}
                    />
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={() => this.updateEntryField('postalCode')}
                      style={{
                        padding: '0.2em',
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                    >
                      Trimite
                    </button>
                  </form>
                </h3>
                <h3>
                  Plată: <br />
                  <span style={{ fontWeight: '400' }}>{entry.plata === '0' ? 'Nu' : 'Da'}</span>
                </h3>
              </div>
            );

            return ''; // Added later, remove if buggy
        })}
        <br />
        <span
          style={{ fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={this.togglePayment}
        >
          Modifică status-ul de plată
        </span>
        <br />
        <br />
        <h3>Încarcă o fotografie nouă</h3>
        {/* <p>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            Atenție! Nu adăuga o secțiune după începerea concursului!
          </span>
        </p> */}
        <div className="AdminLoginForm" style={{ marginTop: '0' }}>
          <form>
            <br />
            <input type="file" onChange={this.fileChanged} name="fotografie_noua" accept="image/*" />
            <br />
            <br />
            <input
              type="text"
              onChange={this.changeHandler}
              name="nume_fotografie"
              placeholder="Numele fotografiei"
            />
            <br />
            <br />

            <select
              name="select_section_id"
              onChange={this.changeHandler}
              style={{ width: '300px', height: '50px', padding: '0.5em' }}
            >
              <option value="" selected>
                Selectează secțiunea
              </option>
              {this.state.sections.map(section => (
                <option value={section.id}>
                  {section.nume_ro} / {section.nume_eng}
                </option>
              ))}
            </select>
            <br />
            <br />
            <button type="button" onClick={this.uploadPhoto}>
              Confirmă
            </button>
          </form>
        </div>
        <br />
        <br />
        {this.state.entries.map(entry => {
          if (entry.id === this.props.id)
            return (
              <div>
                {entry.fotografii.map(fotografie => {
                  let name = fotografie.nume.split('foto_')[1].replaceAll('_', ' ');
                  let lastIndex = name.lastIndexOf(' ');
                  name = name.substring(0, lastIndex);
                  lastIndex = name.lastIndexOf(' ');
                  name = name.substring(0, lastIndex);

                  return (
                    <div>
                      <div className="image-container">
                        <img src={`https://salon.fotoclubproarad.ro/api/salon/${fotografie.nume}`} alt="FotoClubPro Arad" />
                        <p style={{ cursor: 'initial' }}>
                          {name} (
                          {this.state.sections.map(section => {
                            if (section.id === fotografie.id_sectiune)
                              return `${section.nume_ro} / ${section.nume_eng}`;
                            return ''; // Added later, remove if buggy
                          })}
                          )
                        </p>
                        <br />
                        <form>
                          <input
                            type="text"
                            onChange={this.inputChange}
                            name="updatePhotoNameInput"
                            placeholder="Modifică numele fotografiei"
                            style={{
                              padding: '0.2em',
                              marginRight: '0.4em',
                              width: '190px'
                            }}
                          />
                          <br />
                          <br />
                          <button
                            type="button"
                            onClick={() => this.updatePhotoName(fotografie.nume)}
                            style={{
                              padding: '0.2em',
                              marginRight: '0.5em',
                              cursor: 'pointer',
                            }}
                          >
                            Trimite
                          </button>
                        </form>
                        <br />
                        <a
                          href={`https://salon.fotoclubproarad.ro/api/salon/${fotografie.nume}`}
                          download={`${name}_ID${entry.id}`}
                          style={{ fontSize: '18px' }}
                        >
                          Descarcă fotografia
                        </a>
                        <br />
                        <span
                          style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer' }}
                          onClick={() => this.deletePhoto(fotografie.id)}
                        >
                          Șterge fotografia
                        </span>
                      </div>
                      <br />
                      <br />
                    </div>
                  );
                })}
              </div>
            );
            return ''; // Added later, remove if buggy
        })}
        <div className="AdminLoginForm">
          <h3 className="delete-button" onClick={this.deleteEntry}>
            Ștergeți concurentul
          </h3>
          <p>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              Atenție! Acțiunea de ștergere a concurentului este ireversibilă. Va trebui să fie înscris din
              nou (se vor șterge și pozele)!
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default EntryContent;
