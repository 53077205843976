import React, { Component } from 'react';
// import Toolbar from '../../../global/Toolbar/Toolbar';
// import Footer from '../../../global/Footer/Footer';
// import SideDrawer from '../../../global/SideDrawer/SideDrawer';
// import Constrainter from '../../../global/Constrainter/Constrainter';
// import Backdrop from '../../../global/Backdrop/Backdrop';
import JuryLoginForm from './JuryLoginForm';

class JuryLogin extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    // let backdrop;

    // if (this.state.sideDrawerOpen) {
    //   backdrop = <Backdrop click={this.backdropClickHandler} />;
    // }
    return (
      <div className="Status AdminLogin">
        {/* <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter> */}
        <div className="content">
          <h2>Intră în contul de jurat / Enter Jury Account</h2>
          <JuryLoginForm />
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default JuryLogin;
