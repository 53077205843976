import React, { Component } from 'react';
// import Toolbar from '../../../global/Toolbar/Toolbar';
// import Footer from '../../../global/Footer/Footer';
// import SideDrawer from '../../../global/SideDrawer/SideDrawer';
// import Constrainter from '../../../global/Constrainter/Constrainter';

import EntriesContent from './EntriesContent';

class Entries extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  componentDidMount = () => {
    if (!localStorage.getItem('token')) window.location = '/admin/login';
  };

  render() {
    return (
      <div className="Status AdminPanel">
        {/* <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter> */}
        <div className="content">
          <h2>Panou de control Salon FotoClubPro Arad</h2>
          <EntriesContent />
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Entries;
