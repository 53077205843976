import React, { Component } from 'react';
import axios from 'axios';
import './ResultsContent.css';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation, Pagination, FreeMode, Thumbs } from 'swiper';
import Modal from 'react-modal';
import ro from './../../ro.json';
import eng from './../../eng.json';

SwiperCore.use([Navigation, Pagination, FreeMode, Thumbs]);

// Modal.setAppElement('#App');

class ResultsContent extends Component {
  state = {
    modalIsOpen: false,
    modalImage: '',
    sections: [],
    awardedPhotos: [],
    prizeList: [],
    entries: [],
    modalImageName: '',
    modalImageEntryName: '',
    special_prizes: [],
    prizeTitle: ''
  };

  openModal = (image, modalImageName, modalImageEntryName, prizeTitle) => {
    this.setState({ modalIsOpen: true, modalImage: image, modalImageName, modalImageEntryName, prizeTitle });
  };

  closeModal = () => {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modelIsOpen
    }));
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  componentDidMount = () => {
    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_special_prizes.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ special_prizes: response.data });
        // console.log(response.data);
        // console.log(this.state.rules);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_sections.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ sections: response.data });
        // console.log(this.state.sections);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_all_awarded_photos.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ awardedPhotos: response.data });
        // console.log(this.state.awardedPhotos);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_prize_list.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ prizeList: response.data });
        // console.log(this.state.prizeList);
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .post('https://salon.fotoclubproarad.ro/api/salon/get_entries.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(response.data);
        this.setState({ entries: response.data });
        // console.log(this.state.entries);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    const customStyles = {
      // Pentru modal
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
      }
    };

    // 1334 1353 1367 1379

    return (
      <div className="Results" style={{ paddingTop: '5em' }}>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={() => this.setState({ modalIsOpen: false })}
          style={customStyles}
          contentLabel="Results Modal"
        >
          {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
          <div className="modal-image-container">
            <img src={this.state.modalImage} alt="" />
            <div className="text">
              <p>
                <b>{this.state.modalImageName}</b>
              </p>
              <p>{this.state.prizeTitle} - {this.state.modalImageEntryName}</p>
            </div>
          </div>
        </Modal>
   
        <br />
        <br />
        {this.state.special_prizes.map(prize => {
          return (
            <div>
              <h2>{prize.nume}</h2>
              <b>{prize.castigator}</b>
              <br />
              <br />
              <br />
            </div>
          );
        })}
        <br />

        {this.state.sections.map(section => {
          return (
            <div>
              <h2>{lang === ro ? section.nume_ro : section.nume_eng}</h2>

              <div className="titles" style={{lineHeight: '1.7'}}>
                {this.state.awardedPhotos.map(photo => {
                  if (photo.id_sectiune === section.id) {
                    let name = photo.nume_fotografie.split('foto_')[1].replaceAll('_', ' ');
                    let lastIndex = name.lastIndexOf(' ');
                    name = name.substring(0, lastIndex);
                    lastIndex = name.lastIndexOf(' ');
                    name = name.substring(0, lastIndex);
                    return (
                      <div>
                        {/* <div>{name}</div> */}
                        {this.state.entries.map(entry => {
                          if (entry.id === photo.id_participant) {
                           return `${photo.titlu} - ${entry.prenume} ${entry.nume} ${entry.titluri_fotografice} - ${name} - ${entry.tara}`;

                          } else return null;
                        })}
                      </div>
                    );
                  } else return '';
                })}
              </div>
              <div className="content">
              <div className="slider">
                <Swiper
                  navigation={true}
                  modules={[Pagination]}
                  className="mySwiper"
                  slidesPerGroup={1}
                  watchSlidesProgress={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                >
                  {this.state.awardedPhotos.map(awardedPhoto => {
                    if (awardedPhoto.id_sectiune === section.id) {
                      let name = awardedPhoto.nume_fotografie.split('foto_')[1].replaceAll('_', ' ');
                      let lastIndex = name.lastIndexOf(' ');
                      name = name.substring(0, lastIndex);
                      lastIndex = name.lastIndexOf(' ');
                      name = name.substring(0, lastIndex);

                      let entryName = '';
                      this.state.entries.forEach(entry => {
                        if (entry.id === awardedPhoto.id_participant) {
                          entryName = `${entry.prenume} ${entry.nume} ${entry.titluri_fotografice} - ${entry.tara}`;
                        }
                      });

                      return (
                        <SwiperSlide>
                            <img
                              src={`https://salon.fotoclubproarad.ro/api/salon/${awardedPhoto.nume_fotografie}`}
                              alt=""
                              onClick={() =>
                                this.openModal(
                                  `https://salon.fotoclubproarad.ro/api/salon/${awardedPhoto.nume_fotografie}`,
                                  name,
                                  entryName,
                                  awardedPhoto.titlu
                                )
                              }

                              // !!!
                              // Acest onClick functioneaza corespunzator! Trebuie doar scoase comentariile.
                              // !!!
                            />
                            <b>{name}</b>
                            <p>{awardedPhoto.titlu} - {entryName}</p>
                        </SwiperSlide>
                      );
                    } else return '';
                  })}
                </Swiper>
              </div>
            </div>
            </div>
          );
        })}
        <br />
        <br />
        <a href={`/acceptate`} style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: '21px' }}>
          {lang === ro ? 'Apăsați aici pentru fotografiile acceptate' : 'Click here for the accepted photographs'}
        </a>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default ResultsContent;
