import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

class AcceptPhotosContent extends Component {
  state = {
    photos: [],
    modalIsOpen: false,
    modalImage: '',
    imageName: '',
    imageId: '',
    maxGrade: 1,
    selectedGrade: '',
    givenMarksPhotoIds: [],
    givenMarks: [],
    loc_premiu: '',
    titlu_premiu: '',
    acceptedPhotos: [],
    awardedPhotos: [],
    modalImageIsAccepted: undefined,
    modalImageIsAwarded: undefined,
    awardedPhoto: undefined,
    modalImageIndex: undefined,
    entries: [],
    info: {}
    // indexOfSelectedPhoto: ''
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = (image, graded, modalImageIndex, participantAcceptat) => {
    if (graded) {
      let result = this.state.givenMarks.filter(obj => {
        return obj.id_fotografie === image.id;
      });
      this.setState({
        selectedGrade: result[0].nota * 1
      });
    } else {
      this.setState({ selectedGrade: '' });
    }

    let name = image.nume.split('foto_')[1].replaceAll('_', ' ');
    let lastIndex = name.lastIndexOf(' ');
    name = name.substring(0, lastIndex);
    lastIndex = name.lastIndexOf(' ');
    name = name.substring(0, lastIndex);

    let modalImageIsAccepted = this.state.acceptedPhotos.map(photo =>
      true ? photo.id_fotografie === image.id : false
    );

    if (modalImageIsAccepted.includes(true)) modalImageIsAccepted = true;
    else modalImageIsAccepted = false;

    const photoIndex = this.state.awardedPhotos.map(photo => {
      if (photo.id_fotografie === image.id) return true;
      return false;
    });
    let modalImageIsAwarded = false,
      awardedPhoto;
    if (photoIndex.indexOf(true) !== -1) {
      awardedPhoto = this.state.awardedPhotos[photoIndex.indexOf(true)];
      modalImageIsAwarded = true;
    }
    // console.log(awardedPhoto);
    // if (this.state.modalImageIsAccepted === false) modalImageIsAccepted = false;
    // if (this.state.modalImageIsAwarded === false) modalImageIsAwarded = false;

    this.setState({
      modalIsOpen: true,
      modalImage: image,
      imageName: name,
      imageId: image.id,
      modalImageIsAccepted,
      modalImageIsAwarded,
      awardedPhoto,
      modalImageIndex,
      participantAcceptat
    });
  };

  closeModal = () => {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modelIsOpen
    }));
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  deleteSection = () => {
    let r = window.confirm('Sigur doriți să ștergeți secțiunea? Concursul nu trebuie sa fie în desfășurare!');
    if (r === true) {
      const data = new FormData();
      data.append('id', this.props.id);
      data.append('token', localStorage.getItem('token'));
      data.append('jury_token', localStorage.getItem('jury_token'));

      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_section.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          if (response.data) {
            alert('Succes!');
            window.location.href = '/admin/sectiuni';
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  componentDidMount = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('id', this.props.id);


    const data4 = new FormData();
    axios
      .get('https://salon.fotoclubproarad.ro/api/salon/get_entries.php', data4, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(response.data);
        this.setState({ entries: response.data });
        const entries = response.data;
        axios
      .post('https://salon.fotoclubproarad.ro/api/jury/get_section_photos.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        // console.log(this.state.photos.length);

        const photos = response.data;
        // console.log(photos.length);
        let photos2 = [];
        // console.log(photos2);
        // for (const [i, photo] of photos.entries()) { // Old version
        for (const photo of photos.entries()) {
          for (const entry of entries) {
            // console.log(entry);
            if (entry.id === photo[1].id_participant && entry.plata === '1') {
              // photos2.splice(i);
              photos2.push(photo[1]);
              // console.log(photo);
            }
          }
        }
        // console.log(photos2.length);
        this.setState({ photos: photos2 });
      })
      .catch(e => {
        console.log(e);
      });
      })
      .catch(e => {
        console.log(e);
      });


    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ maxGrade: response.data[0].nota_maxima, info: response.data[0] });
      })
      .catch(e => {
        console.log(e);
      });

    const data3 = new FormData();
    data3.append('token', localStorage.getItem('token'));
    data3.append('jury_token', localStorage.getItem('jury_token'));

    data3.append('id_sectiune', this.props.id);
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_accepted_photos.php', data3, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ acceptedPhotos: response.data });
      })
      .catch(e => {
        console.log(e);
      });

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_awarded_photos.php', data3, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ awardedPhotos: response.data });
      })
      .catch(e => {
        console.log(e);
      });

    
  };

  givePrize = () => {
    if (!this.state.loc_premiu || !this.state.titlu_premiu)
      return alert('Scrie titlul premiului și selectează locul!');
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));

    data.append('id_fotografie', this.state.modalImage.id);
    data.append('nume_fotografie', this.state.modalImage.nume);
    data.append('id_participant', this.state.modalImage.id_participant);
    data.append('id_sectiune', this.state.modalImage.id_sectiune);
    data.append('loc', this.state.loc_premiu);
    data.append('titlu', this.state.titlu_premiu);
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_prize.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        alert('Succes!');
        this.setState({
          awardedPhoto: {
            id_fotografie: this.state.modalImage.id,
            nume_fotografie: this.state.modalImage.nume,
            id_participant: this.state.modalImage.id_participant,
            id_sectiune: this.state.modalImage.id_sectiune,
            loc: this.state.loc_premiu,
            titlu: this.state.titlu_premiu
          },
          awardedPhotos: [
            ...this.state.awardedPhotos,
            {
              id_fotografie: this.state.modalImage.id,
              nume_fotografie: this.state.modalImage.nume,
              id_participant: this.state.modalImage.id_participant,
              id_sectiune: this.state.modalImage.id_sectiune,
              loc: this.state.loc_premiu,
              titlu: this.state.titlu_premiu
            }
          ],
          modalImageIsAwarded: true
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  acceptPhoto = () => {
    const data = new FormData();
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('token', localStorage.getItem('token'));
    data.append('id_fotografie', this.state.modalImage.id);
    data.append('nume_fotografie', this.state.modalImage.nume);
    data.append('id_participant', this.state.modalImage.id_participant);
    data.append('id_sectiune', this.state.modalImage.id_sectiune);
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_accepted_photo.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // alert('Succes!');
        this.setState({
          modalImageIsAccepted: true,
          acceptedPhotos: [
            ...this.state.acceptedPhotos,
            {
              id_fotografie: this.state.modalImage.id,
              nume_fotografie: this.state.modalImage.nume,
              id_participant: this.state.modalImage.id_participant,
              id_sectiune: this.state.modalImage.id_sectiune
            }
          ]
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  deletePrize = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('id_fotografie', this.state.modalImage.id);
    data.append('jury_token', localStorage.getItem('jury_token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/delete_prize.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        alert('Premiu șters cu succes!');
        this.setState({ modalImageIsAwarded: false });
      })
      .catch(e => {
        console.log(e);
      });
  };

  deleteAcceptedPhoto = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('jury_token', localStorage.getItem('jury_token'));

    data.append('id_fotografie', this.state.modalImage.id);

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/delete_accepted_photo.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        alert('Acceptarea fotografiei ștearsă cu succes! / The photograph is no longer accepted!');
        this.setState({ modalImageIsAccepted: false });
        // console.log(this.state.)
        this.setState(prevState => ({
          acceptedPhotos: prevState.acceptedPhotos.filter(
            photo => photo.id_fotografie !== prevState.modalImage.id
          )
        }));
      })
      .catch(e => {
        console.log(e);
      });
  };

  next = () => {
    const nextImage = this.state.photos[this.state.photos.indexOf(this.state.modalImage) + 1];
    this.openModal(
      nextImage,
      this.state.givenMarksPhotoIds.includes(nextImage.id) ? true : false,
      this.state.modalImageIndex + 1
    );
  };

  prev = () => {
    const prevImage = this.state.photos[this.state.photos.indexOf(this.state.modalImage) - 1];
    this.openModal(
      prevImage,
      this.state.givenMarksPhotoIds.includes(prevImage.id) ? true : false,
      this.state.modalImageIndex + 1
    );
  };

  render() {
    return (
      <div className="SectionsContent SectionContent JurySectionContent">
        {this.state.info.rezultate_afisate === '0' || localStorage.getItem('token') ? (
          <div>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={() => this.setState({ modalIsOpen: false })}
              style={{
                // Pentru modal
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)'
                }
              }}
              contentLabel="Modal"
            >
              {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
              <div
                className="modal-image-wrapper"
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <img
                  src={`https://salon.fotoclubproarad.ro/api/salon/${this.state.modalImage.nume}`}
                  className="jury-img"
                  alt=""
                  style={{ margin: '0 auto' }}
                />
                <div className="title" style={{ alignSelf: 'center', marginTop: '0.7em' }}>
                  <p style={{ textAlign: 'center' }}>
                    <b>{this.state.modalImageIndex + 1}. </b>
                    <b>{this.state.imageName}</b>
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <b>ID fotograf: {this.state.modalImage.id_participant}</b>
                  </p>
                  <p style={{ fontSize: '18px', textAlign: 'center', color: 'darkred', fontWeight: 'bold' }}>
                    {this.state.participantAcceptat
                      ? 'Fotograful a fost deja acceptat! / This photographer has already been accepted!'
                      : ''}
                  </p>
                  {/* <p style={{ textAlign: 'center' }}>
                    <b>
                      {this.state.entries.map(entry => {
                        if (entry.id === this.state.modalImage.id_participant)
                          return `${entry.prenume} ${entry.nume} (${entry.tara}) ID: ${entry.id}`;
                        else return null;
                      })}
                    </b>
                  </p> */}
                  <p style={{ fontSize: '18px', textAlign: 'center', color: '#00ff00' }}>
                    <b>
                      {this.state.modalImageIsAccepted ? 'Fotografie acceptată / Accepted photograph' : ''}
                    </b>
                  </p>
                  <p style={{ fontSize: '18px', textAlign: 'center', color: '#00ff00' }}>
                    <b>
                      {this.state.awardedPhoto && this.state.modalImageIsAwarded
                        ? 'Fotografie premiată / Awarded photograph: ' + this.state.awardedPhoto.titlu
                        : ''}
                    </b>
                  </p>
                </div>

                <div style={{ alignSelf: 'center' }}>
                  <form>
                    <br />
                    <button
                      type="button"
                      onClick={this.acceptPhoto}
                      style={{
                        padding: '0.2em',
                        margin: '0 auto',
                        cursor: 'pointer',
                        width: '150px',
                        display: 'block'
                      }}
                    >
                      Acceptă fotografia / Accept photograph
                    </button>
                    <div style={{ marginTop: '0.4em', textAlign: 'center' }}>
                      <button
                        type="button"
                        onClick={this.deleteAcceptedPhoto}
                        style={{
                          padding: '0.2em',
                          margin: '0 auto',
                          cursor: 'pointer',
                          width: '150px',
                          display: 'block'

                        }}
                      >
                        Șterge acceptarea / Remove acceptance
                      </button>
                    </div>
                  </form>
                  <br />

                  <p style={{textAlign: 'center', color: 'white'}}>
                    <b>Fotografii acceptate: <span style={{color: '#00ff00'}}>{this.state.acceptedPhotos.length}</span></b>
                  </p>
                  <br />
                </div>

                <div className="next-prev">
                  <span onClick={this.prev}>
                    <FontAwesomeIcon icon={faArrowLeft} className="icon" size="2x" />
                  </span>
                  <br />
                  <span onClick={this.next}>
                    <FontAwesomeIcon icon={faArrowRight} className="icon" size="2x" />
                  </span>
                </div>
              </div>
            </Modal>
            {/* */}

            <div className="AdminLoginForm">
              <h3 style={{ marginLeft: '2.3em', marginBottom: '2.5em', lineHeight: '1.7' }}>
                {/* Fotografiile sunt ordonate în ordinea notelor începând cu fotografie de pe locul 66 / The
                photographs are ordered by the grades they received, starting with the 66th photograph.
                <br /> */}
                Apăsați pe o fotografie pentru a o accepta / Click on a photograph to accept it
                <br />
                <b style={{ color: 'red' }}>
                  Fotografiile cu margine roșie au ca autor un fotograf deja acceptat / The photographs with
                  red borders have an author that has already been accepted!
                </b>
                <br />
                <b style={{ color: 'green' }}>
                  Fotografiile cu margine verde au fost acceptate deja / The photographs with green borders
                  have already been accepted!
                </b>
              </h3>
              <p style={{textAlign: 'center', color: 'black', cursor: 'initial'}}>
                    <b>Fotografii acceptate: <span style={{color: 'green'}}>{this.state.acceptedPhotos.length}</span></b>
              </p>
              <br />
              <br />
              <div class="gallery">
                {this.state.photos.map((photo, index) => {
                  // if (index >= 65)
                  // this.state.acceptedPhotos
                  let participantAcceptat = 0,
                    fotografieAcceptata = 0;
                  for (const acceptedPhoto of this.state.acceptedPhotos) {
                    if (acceptedPhoto.id_participant === photo.id_participant) participantAcceptat = 1;
                    if (acceptedPhoto.id_fotografie === photo.id) fotografieAcceptata = 1;
                  }

                  let border = '8px solid transparent';
                  if (participantAcceptat) border = '8px solid red';
                  if (fotografieAcceptata) border = '8px solid green';
                  return (
                    <figure class="gallery__item gallery__item--1">
                      <img
                        loading="lazy"
                        src={`https://salon.fotoclubproarad.ro/api/salon/${photo.nume}`}
                        class="gallery__img"
                        alt="FotoClubPro Arad"
                        style={{
                          border: border,
                          borderRadius: '0px'
                        }}
                        onClick={() =>
                          this.openModal(
                            photo,
                            this.state.givenMarksPhotoIds.includes(photo.id) ? true : false,
                            index,
                            participantAcceptat
                          )
                        }
                      />
                      <div style={{ fontSize: '20px' }}>
                        <b>
                          {/* {index + 1}. ({(Math.round(photo.medie * 100) / 100).toFixed(2)}P)  */}
                          ID fotograf: {photo.id_participant}
                        </b>
                      </div>
                    </figure>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <h2>
            Rezultatele au fost afișate! Nu se mai pot accepta fotografii! / The results are public! You can
            no longer accept photographs.
          </h2>
        )}
      </div>
    );
  }
}

export default AcceptPhotosContent;
