import React, { Component } from 'react';
import './App.css';

import Root from './routes/root/Root';
import Registration from './routes/inscriere/Registration';
import Status from './routes/status/Status';
import Results from './routes/rezultate/Results';
import ScrollToTop from 'react-scroll-to-top';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AdminLogin from './routes/admin/Login/AdminLogin';
import AdminPanel from './routes/admin/Panel/AdminPanel';
import Sections from './routes/admin/Panel/Sections/Sections';
import Section from './routes/admin/Panel/Section/Section';
import Entries from './routes/admin/Panel/Entries/Entries';
import Entry from './routes/admin/Panel/Entry/Entry';
import Jury from './routes/admin/Panel/Jury/Jury';
import Info from './routes/admin/Panel/Info/Info';
import JuryLogin from './routes/juriu/JuryLogin/JuryLogin';
import JuryPanel from './routes/juriu/Panel/JuryPanel';
import JurySection from './routes/juriu/Panel/Section/JurySection';
import AwardedPhotos from './routes/admin/Panel/Section/AwardedPhotos/AwardedPhotos';
import AcceptedPhotos from './routes/admin/Panel/Section/AcceptedPhotos/AcceptedPhotos';
import AcceptPhotos from './routes/admin/Panel/Section/AcceptPhotos/AcceptPhotos';
import Accepted from './routes/acceptate/Accepted';
import Contact from './routes/contact/Contact';
import Rules from './routes/regulament/Rules';
import Payment from './routes/plata/Payment';

class App extends Component {
  componentDidMount = () => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en');
      window.location.reload();
    }
  };

  render() {
    return (
      <div className="App" id="App">
        <ScrollToTop smooth color="#ffffff" />

        <Router>
          <Switch>
            {/* <Route path="/termeni-și-condiții">
            <TermsAndConditions />
          </Route>
          <Route path="/users"></Route> */}
            <Route path="/" exact>
              <Root />
            </Route>
            <Route path="/acasă" exact>
              <Root />
            </Route>
            <Route path="/înscriere" exact>
              <Registration />
            </Route>
            <Route path="/status" exact>
              <Status />
            </Route>
            <Route path="/rezultate" exact>
              <Results />
            </Route>
            <Route path="/contact" exact>
              <Contact />
            </Route>
            <Route path="/regulament" exact>
              <Rules />
            </Route>
            <Route path="/plată" exact>
              <Payment />
            </Route>

            <Route path="/acceptate" exact component={Accepted} />

            <Route path="/admin/login" exact>
              <AdminLogin />
            </Route>

            <Route path="/admin" exact>
              <AdminPanel />
            </Route>

            <Route path="/admin/sectiuni" exact>
              <Sections />
            </Route>

            <Route path="/admin/sectiune/:id" exact component={Section} />
            <Route path="/admin/inscrisi/" exact component={Entries} />
            <Route path="/admin/concurent/:id" exact component={Entry} />
            <Route path="/admin/juriu/" exact component={Jury} />
            <Route path="/admin/info/" exact component={Info} />
            <Route path="/admin/premiate/:id" exact component={AwardedPhotos} />
            <Route path="/admin/acceptate/:id" exact component={AcceptedPhotos} />
            <Route path="/admin/accepta/:id" exact component={AcceptPhotos} />
            <Route path="/juriu/login/" exact component={JuryLogin} />
            <Route path="/juriu/" exact component={JuryPanel} />
            <Route path="/juriu/sectiune/:id/:name" exact component={JurySection} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
