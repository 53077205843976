import React, { Component } from 'react';
import axios from 'axios';

class JuryContent extends Component {
  state = {
    jurors: [],
    nume: '',
    prenume: '',
    jurorPass: ''
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_jurors.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(this.state);
        this.setState({ jurors: response.data });
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addJuror = () => {
    if (!this.state.nume || !this.state.prenume) return alert('Te rog să scrii numele juratului!');
    const data = new FormData();
    data.append('nume', this.state.nume);
    data.append('prenume', this.state.prenume);
    data.append('token', localStorage.getItem('token'));

    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/add_juror.php', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        // console.log(response.data);
        if (response.data) {
          // alert('Succes!');
          this.setState({
            jurorPass: `Parola juratului ${this.state.prenume} ${this.state.nume} este ${response.data}`
          });
          // window.location.href = '/projects';
          // window.location.reload();
        } else alert('Eroare!');
      })
      .catch(e => {
        console.log(e.response);
        console.log('Error');
      });
  };

  resetPassword = id => {
    let r = window.confirm('Sigur doriți să resetați parola?');
    if (r === true) {
      const data = new FormData();
      data.append('id', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/reset_juror_password.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert(`Succes! Noua parolă este ${response.data}`);
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  deleteJuror = id => {
    let r = window.confirm('Sigur doriți să ștergeți juratul?');
    if (r === true) {
      const data = new FormData();
      data.append('id', id);
      data.append('token', localStorage.getItem('token'));
      axios
        .post('https://salon.fotoclubproarad.ro/api/admin/delete_juror.php', data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Client-ID aa4f70aa066eefe'
          }
          // Authorization: 'Bearer ' + varToken
        })
        .then(response => {
          // console.log(response);
          if (response.data) {
            alert('Succes!');
            window.location.reload();
          }
          // window.location.href = '/projects';
        })
        .catch(e => {
          console.log(e.response);
          console.log('Error');
        });
    }
  };

  render() {
    return (
      <div className="SectionsContent SectionContent EntryContent">
        <br />
        <h3>Adaugă un nou jurat</h3>
        {/* <p>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            Atenție! Nu adăuga o secțiune după începerea concursului!
          </span>
        </p> */}
        <div className="AdminLoginForm" style={{ marginTop: '0' }}>
          <form>
            <br />
            <br />
            <input type="text" onChange={this.changeHandler} name="prenume" placeholder="Prenume" />
            <br />
            <br />
            <input type="text" onChange={this.changeHandler} name="nume" placeholder="Nume" />

            <br />
            <br />
            <button type="button" onClick={this.addJuror}>
              Confirmă
            </button>

            <span>
              <br />
              <br />
              <b>{this.state.jurorPass}</b>
            </span>
          </form>
        </div>
        {this.state.jurors.map(juror => {
          return (
            <div className="entry-details">
              <h3>
                <span style={{ fontWeight: '400', cursor: 'initial' }}>
                  {juror.prenume} {juror.nume}
                </span>
                <br />
                <span style={{ cursor: 'pointer' }} onClick={() => this.resetPassword(juror.id)}>
                  Resetează parola pentru {juror.prenume} {juror.nume}
                </span>
                <br />
                <br />
                <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.deleteJuror(juror.id)}>
                  Șterge juratul {juror.prenume} {juror.nume}
                </span>
              </h3>
            </div>
          );
        })}
      </div>
    );
  }
}

export default JuryContent;
