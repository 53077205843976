import React from 'react';
import ro from './../../ro.json';
import eng from './../../eng.json';
let lang;
if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
  lang = ro;
} else {
  lang = eng;
}

const links = lang.global.nav.map(el => el);

const Links = links.map(link => {
  let to = `${window.location.protocol}//${window.location.host}/${link.toLowerCase()}`;
  if (to === `${window.location.protocol}//${link.toLowerCase()}/contact`)
    to = `http://${link.toLowerCase()}/contact`;
  if (link === 'Acasă' || link === 'Home') to = `${window.location.protocol}//${window.location.host}`;
  if (link === 'Registration') to = `${window.location.protocol}//${window.location.host}/înscriere`;
  if (link === 'Results') to = `${window.location.protocol}//${window.location.host}/rezultate`;
  if (link === 'Stare') to = `${window.location.protocol}//${window.location.host}/status`;
  // if (link === 'Regulament' && localStorage.getItem('lang') === 'ro')
  //   to = `https://salon.fotoclubproarad.ro/api/admin/uploads/regulament_ro.pdf`;
  // if (link === 'Rules' && localStorage.getItem('lang') === 'eng')
  //   to = `https://salon.fotoclubproarad.ro/api/admin/uploads/regulament_eng.pdf`;
  if (link === 'Regulament' || link === 'Rules')
    to = `${window.location.protocol}//${window.location.host}/regulament`;
  if (link === 'Plată' || link === 'Payment')
    to = `${window.location.protocol}//${window.location.host}/plată`;

  return (
    <li key={link}>
      <a
        href={to}
        target={
          to === 'https://salon.fotoclubproarad.ro/api/admin/uploads/regulament_ro.pdf' ||
          to === 'https://salon.fotoclubproarad.ro/api/admin/uploads/regulament_eng.pdf'
            ? '__blank'
            : ''
        }
      >
        {link}
      </a>
    </li>
  );
});

export default Links;
