import DrawerToggleButton from './../SideDrawer/DrawerToggleButton';
import axios from 'axios';
import './Toolbar.css';
import logo from './../../assets/logo.png';
import Links from './Elements';
import Zoom from 'react-reveal/Zoom';
import React, { Component } from 'react';
import roFlag from './../../assets/ro.png';
import ukFlag from './../../assets/uk.png';
import Countdown from 'react-countdown';

class Toolbar extends Component {
  state = {
    scrolled: false,
    info: {}
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isTop = window.scrollY < 200;
      if (!isTop) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ info: response.data[0] });
        // console.log(this.state.info);
      })
      .catch(e => {
        console.log(e);
      });
  }

  toggleLang = () => {
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang'))
      localStorage.setItem('lang', 'eng');
    else localStorage.setItem('lang', 'ro');
    window.location.reload();
  };

  render() {
    const { scrolled } = this.state;
    let toolbarClasses = 'toolbar';
    if (scrolled) toolbarClasses += ' scrolled';
    if (!this.props.page) toolbarClasses += ' non-root-toolbar';
    let flag;

    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) flag = ukFlag;
    else flag = roFlag;

    return (
      <header className={toolbarClasses} id="toolbar">
        <div className="inner-toolbar">
          <div className="spacer-container hamburger-spacer">
            <div className="toolbar-toggle-button">
              <DrawerToggleButton click={this.props.drawerClickHandler} />
            </div>
          </div>
          <div className="spacer-container title-spacer">
            <a href="/">
              <Zoom>
                <h1 className="title">FotoClubPro Arad</h1>
              </Zoom>
            </a>
          </div>
          <div className="spacer-container spacer-container-center">
            <div className="toolbar-navigation-items">
              <ul>
                {Links}
                <img
                  src={flag}
                  alt="Photography international arad fotografie vernisaj"
                  style={{
                    height: '2em',
                    marginLeft: '1em',
                    cursor: 'pointer',
                    display: 'block',
                    zIndex: '2'
                  }}
                  onClick={this.toggleLang}
                />
                <div style={{ marginLeft: '2em', color: 'white' }}>
                  {' '}
                  <Countdown
                    // date={
                    //   new Date(
                    //     this.state.info.inscrieri_deschise === '1'
                    //       ? this.state.info.data_incheiere
                    //       : this.state.info.data_incepere
                    //   )
                    // }
                    date={
                      new Date(
                        this.state.info.data_incheiere
                      )
                    }
                  />
                </div>
              </ul>
            </div>
          </div>

          <div className="spacer-container spacer-container-logo">
            <a href="https://fotoclubproarad.ro">
              <Zoom>
                <img
                  src={logo}
                  alt="fotograf pro club arad fotografie salon concurs poze poza alb negru color"
                  className="logo"
                />
              </Zoom>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Toolbar;
