import React, { Component } from 'react';
import axios from 'axios';
import './JurySectionContent.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

class JurySectionContent extends Component {
  state = {
    photos: [],
    modalIsOpen: false,
    modalImage: '',
    imageName: '',
    imageId: '',
    maxGrade: 1,
    selectedGrade: '',
    givenMarksPhotoIds: [],
    givenMarks: [],
    info: {},
    entries: []
    // indexOfSelectedPhoto: ''
  };

  inputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = (image, graded) => {
    if (graded) {
      let result = this.state.givenMarks.filter(obj => {
        return obj.id_fotografie === image.id;
      });
      this.setState({
        selectedGrade: result[0].nota * 1
      });
    } else {
      this.setState({ selectedGrade: '' });
    }

    let name = image.nume.split('foto_')[1].replaceAll('_', ' ');
    let lastIndex = name.lastIndexOf(' ');
    name = name.substring(0, lastIndex);
    lastIndex = name.lastIndexOf(' ');
    name = name.substring(0, lastIndex);
    this.setState({
      modalIsOpen: true,
      modalImage: image,
      imageName: name,
      imageId: image.id
    });
  };

  closeModal = () => {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modelIsOpen
    }));
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  componentWillMount = () => {
    const data4 = new FormData();
    axios
      .get('https://salon.fotoclubproarad.ro/api/salon/get_entries.php', data4, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // console.log(response.data);
        this.setState({ entries: response.data });
        // console.log(this.state.entries);
      })
      .catch(e => {
        console.log(e);
      });
  }
  componentDidMount = () => {
    setTimeout(() => {
      // console.log(photos2);
      axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ info: response.data[0] });
      })
      .catch(e => {
        console.log(e);
      });
    const data = new FormData();
    data.append('id', this.props.id);
    data.append('jury_token', localStorage.getItem('jury_token'));
    axios
      .post('https://salon.fotoclubproarad.ro/api/jury/get_section_photos.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const photos = response.data;
        // console.log(photos.length);
        let photos2 = [];
        // console.log(photos2);
        // console.log(this.state.entries);
        // for (const [i, photo] of photos.entries()) { // Old version
        for (const photo of photos.entries()) {
          for (const entry of this.state.entries) {
            // console.log(photo);
            if (entry.id === photo[1].id_participant && entry.plata === '1') {
              // photos2.splice(i);
              photos2.push(photo[1]);
              // console.log(photo);
            }
          }
        }
        this.setState({ photos: photos2 });
        // console.log(photos2.length);
   
      })
      .catch(e => {
        console.log(e);
      });
    }, 3000);
    const data = new FormData();
    data.append('id', this.props.id);
    data.append('jury_token', localStorage.getItem('jury_token'));
  

    axios
      .get('https://salon.fotoclubproarad.ro/api/admin/get_info.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ maxGrade: response.data[0].nota_maxima });
      })
      .catch(e => {
        console.log(e);
      });

    const data2 = new FormData();
    data2.append('jury_token', localStorage.getItem('jury_token'));
    axios
      .post('https://salon.fotoclubproarad.ro/api/jury/get_juror_marks.php', data2, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        let result = response.data.map(a => a.id_fotografie);
        this.setState({ givenMarksPhotoIds: result, givenMarks: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  giveGrade = (imageId, grade) => {
    const data = new FormData();
    data.append('jury_token', localStorage.getItem('jury_token'));
    data.append('nota', grade);
    data.append('id_fotografie', imageId);
    axios
      .post('https://salon.fotoclubproarad.ro/api/jury/give_mark.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // this.setState({ photos: response.data });
        this.setState({
          selectedGrade: grade,
          givenMarksPhotoIds: [...this.state.givenMarksPhotoIds, imageId]
        });

        const data2 = new FormData();
        data2.append('jury_token', localStorage.getItem('jury_token'));
        axios
          .post('https://salon.fotoclubproarad.ro/api/jury/get_juror_marks.php', data2, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            let result = response.data.map(a => a.id_fotografie);
            this.setState({ givenMarksPhotoIds: result, givenMarks: response.data });
          })
          .catch(e => {
            console.log(e);
          });
      })
      .catch(e => {
        console.log(e);
      });
  };

  next = () => {
    const nextImage = this.state.photos[this.state.photos.indexOf(this.state.modalImage) + 1];
    this.openModal(nextImage, this.state.givenMarksPhotoIds.includes(nextImage.id) ? true : false);
  };

  prev = () => {
    const prevImage = this.state.photos[this.state.photos.indexOf(this.state.modalImage) - 1];
    this.openModal(prevImage, this.state.givenMarksPhotoIds.includes(prevImage.id) ? true : false);
  };

  render() {
    return (
      <div className="SectionsContent SectionContent JurySectionContent">
        {this.state.info.jurizari_deschise === '1' || localStorage.getItem('token') ? (
          <div>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={() => this.setState({ modalIsOpen: false })}
              style={{
                // Pentru modal
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)'
                }
              }}
              contentLabel="Modal"
            >
              {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
              <div className="modal-image-wrapper" >
                <img
                  src={`https://salon.fotoclubproarad.ro/api/salon/${this.state.modalImage.nume}`}
                  className="jury-img jury_photo"
                  alt=""
                  
                />
                <div className="title">
                  <p>
                    <b>{this.state.imageName}</b>
                    {/* {this.state.modalImage.id_participant} */}
                  </p>
                </div>
                <div className="grades">
                  {Array.from({ length: this.state.maxGrade }, (_, i) => i + 1).map(grade => {
                    return (
                      <span
                        onClick={() => this.giveGrade(this.state.imageId, grade)}
                        style={{ color: this.state.selectedGrade === grade ? 'rgb(15, 241, 15)' : '#fff' }}
                      >
                        {grade}
                      </span>
                    );
                  })}
                </div>
                <div className="next-prev">
                  <span onClick={this.prev}>
                    <FontAwesomeIcon icon={faArrowLeft} className="icon" size="2x" />
                  </span>
                  <br />
                  <span onClick={this.next}>
                    <FontAwesomeIcon icon={faArrowRight} className="icon" size="2x" />
                  </span>
                </div>
              </div>
            </Modal>

            <div className="AdminLoginForm">
              <h3 style={{ marginLeft: '2.3em', marginBottom: '2.5em' }}>
                Apăsați pe o fotografie pentru a acorda o notă. Fotografiile cu fundal roșu sunt cele pe care
                încă nu le-ați notat.
                <br />
                <br />
                Click on a photograph to grade it. The photographs with a red background are not graded yet.
              </h3>
              <div class="gallery">
                {this.state.photos.map(photo => {
                  // console.log(photo);

                  return (
                    <figure class="gallery__item gallery__item--1" onClick={() =>
                      this.openModal(
                        photo,
                        this.state.givenMarksPhotoIds.includes(photo.id) ? true : false
                      )
                    } style={{cursor: 'pointer'}}>
                      <img
                        loading="lazy"
                        src={`https://salon.fotoclubproarad.ro/api/salon/${photo.nume}`}
                        class="gallery__img jury_photo"
                        alt="FotoClubPro Arad"
                        style={{
                          borderColor: this.state.givenMarksPhotoIds.includes(photo.id) ? 'green' : 'darkred'
                        }}
                      />
                    </figure>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <h2>
            Jurizările au fost închise sau urmeaza să fie deschise! / The judging is over or it is yet to
            begin!
          </h2>
        )}
      </div>
    );
  }
}

export default JurySectionContent;
