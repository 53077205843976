import React, { Component } from 'react';
import axios from 'axios';
import './AdminPanelContent.css';

class AdminPanelContent extends Component {
  state = {
    entriesNumber: ''
  };

  logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  componentDidMount = () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    axios
      .post('https://salon.fotoclubproarad.ro/api/admin/get_entries_count.php', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ entriesNumber: response.data.numar_participanti });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    return (
      <div className="AdminPanelContent">
        <p style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer' }} onClick={this.logout}>
          Ieși din cont
        </p>

        <a href="/admin/sectiuni">Secțiuni</a>
        <a href="/admin/inscrisi">
          Concurenți înscriși (<b style={{ color: 'red' }}>{this.state.entriesNumber}</b> concurenți)
        </a>
        <a href="/admin/juriu">Juriu</a>
        <a href="/admin/info">Informații salon</a>
      </div>
    );
  }
}

export default AdminPanelContent;
