import React, { Component } from 'react';
import './Contact.css';
import axios from 'axios';
import Toolbar from '../../global/Toolbar/Toolbar';
import Footer from '../../global/Footer/Footer';
import SideDrawer from '../../global/SideDrawer/SideDrawer';
import Constrainter from '../../global/Constrainter/Constrainter';
import Backdrop from '../../global/Backdrop/Backdrop';
import ro from './../../ro.json';
import eng from './../../eng.json';

class Contact extends Component {
  state = {
    sideDrawerOpen: false,
    nume: '',
    prenume: '',
    email: '',
    telefon: '',
    mesaj: ''
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitHandler = event => {
    event.preventDefault();
    const reqBody = { ...this.state };
    axios
      .post('https://salon.fotoclubproarad.ro/api/salon/contact_form.php', reqBody)
      .then(response => {
        this.response = { ...response };
        var r = window.confirm('Success!');
        if (r === true) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      })
      .catch(e => {
        alert('Error sending the message');
      });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    let lang;
    if (localStorage.getItem('lang') === 'ro' || !localStorage.getItem('lang')) {
      lang = ro;
    } else {
      lang = eng;
    }
    const { nume, prenume, email, telefon, mesaj } = this.state;
    return (
      <div className="Status">
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <Constrainter>
          <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
          {backdrop}
        </Constrainter>
        <div className="content">
          <div className="contact-form">
            {/* <h2 className="section-title" id="contact" style={{ paddingTop: '5em' }}>
          Contactați-ne
        </h2> */}
            <p className="details">
              {lang.contact.contact_text}
              <b>{lang.contact.contact_text_bold}</b>.
            </p>

            <div className="form-container">
              <form className="contact-form" onSubmit={this.submitHandler}>
                <div className="row">
                  <input
                    type="text"
                    placeholder={lang.contact.form.last_name}
                    name="nume"
                    onChange={this.changeHandler}
                    required
                    value={nume}
                  />
                  <input
                    type="text"
                    placeholder={lang.contact.form.first_name}
                    name="prenume"
                    onChange={this.changeHandler}
                    required
                    value={prenume}
                  />
                </div>

                <div className="row">
                  <input
                    type="email"
                    placeholder={lang.contact.form.email}
                    name="email"
                    onChange={this.changeHandler}
                    required
                    value={email}
                  />
                  <input
                    type="phone"
                    placeholder={lang.contact.form.phone}
                    name="telefon"
                    onChange={this.changeHandler}
                    required
                    value={telefon}
                  />
                </div>

                <textarea
                  type="text"
                  name="mesaj"
                  className="long"
                  placeholder={lang.contact.form.message}
                  onChange={this.changeHandler}
                  required
                  value={mesaj}
                />

                <button type="submit" className="submit-button">
                  {lang.contact.form.submit}
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
